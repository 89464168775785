/* eslint-disable @typescript-eslint/no-unused-vars */
import {AccountInfo, SilentRequest} from '@azure/msal-browser';
import {useMsal} from '@azure/msal-react';
import {Accordion, AccordionDetails, AccordionSummary, Box, Grid} from '@material-ui/core';
import {makeStyles, Theme} from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {Alert, Rating} from '@material-ui/lab';
import Skeleton from '@material-ui/lab/Skeleton';
import classNames from 'classnames';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useHistory, useLocation} from 'react-router';
import {v4 as uuidv4} from 'uuid';
import {AUTH_REQUESTS} from '../../../../authConfig';
import {useAuthFunctions, useAuthState} from '../../../../AuthContext';
import useDateTimeNormalizer from '../../../../client/common/normalizer/dateTimeNormalizer';
import Normalizer from '../../../../client/common/normalizer/normalizer';
import {ReferenceApi} from '../../../../client/openapitools/common/api';
import {Order} from '../../../../client/openapitools/seller';
import {OrderApi, OrderRefund} from '../../../../client/openapitools/seller/api';
import {Configuration} from '../../../../client/openapitools/seller/configuration';
import BSCCardBox from '../../../../features/common/bscCardBox/bscCardBox';
import BSCContainer from '../../../../features/common/bscContainer/bscContainer';
import BSCCurrency from '../../../../features/common/bscCurrency/bscCurrency';
import BSCDivider from '../../../../features/common/bscDivider/bscDivider';
import BSCGridTableRow from '../../../../features/common/bscGridTableRow/bscGridTableRow';
import BSCHeaderBlock from '../../../../features/common/bscHeaderBlock/bscHeaderBlock';
import BSCTypography from '../../../../features/common/bscTypography/bscTypography';
import useReduxToast from '../../../../features/hooks/redux/toast/useReduxToast';
import {useQuery} from '../../../../packages/core/src/hooks/useQuery';
import useScreenSnap from '../../../../packages/core/src/hooks/useScreenSnap';
import {currencyFormatter} from '../../../../packages/core/src/util/util';
import Cards from '../../../marketplace/pages/dashboard/tabs/messages/components/cards';
import OrderDetailsHeader from './orderDetailsHeader';
import OrderRefundModal from './OrderRefundModal';
import PackingSlip from './packingSlip';
import PrintLabelModal from './PrintLabelModal';
import ShippingStatus from './shipping/shippingStatus';

const useStyles = makeStyles((theme: Theme) => ({
    header: {
        borderRadius: '4px 4px 0px 0',
        boxShadow: 'inset 0px -4px 4px #DAE0E6',
        padding: theme.spacing(1.5),
        textAlign: 'center',
        [theme.breakpoints.up('md')]: {
            textAlign: 'left',
        },
    },
    bgGrayLight: {
        backgroundColor: '#F5F8FA',
    },
    revert: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',
        },
    },
    orderTotalContent: {
        '& p': {
            [theme.breakpoints.down('sm')]: {
                fontSize: theme.typography.subtitle2.fontSize,
            },
        },
    },
    marginTop: {
        marginTop: theme.spacing(2),
    },
    paddingRight: {
        [theme.breakpoints.up('sm')]: {
            paddingRight: theme.spacing(2),
        },
    },
    roundedBottoms: {
        borderRadius: '0px 4px 4px',
    },
    noPrint: {
        display: 'flex',
        '@media print': {
            display: 'none',
        },
    },
    hidden: {
        display: 'none',
    },
    Printable: {
        '@media screen': {
            display: 'none',
        },
        '@media print': {
            position: 'absolute',
            top: 0,
            display: 'flex',
            breakAfter: 'always !important',
            pageBreakAfter: 'always !important',
            pageBreakInside: 'avoid !important',
            '& table': {
                pageBreakInside: 'avoid !important',
            },
        },
    },
}));

const ShippingNormalizer = {
    getName: shippingInfo => {
        if (!shippingInfo?.firstName && !shippingInfo?.lastName) {
            return null;
        }
        return `${shippingInfo?.firstName ? shippingInfo?.firstName + ' ' : ''}${shippingInfo?.lastName ?? ''}`;
    },
    getAddress: shippingInfo => {
        if (!shippingInfo?.addressLine1 && !shippingInfo?.addressLine2) {
            return null;
        }
        return `${shippingInfo?.addressLine1 ? shippingInfo?.addressLine1 + ' ' : ''}${shippingInfo?.addressLine2 ?? ''}`;
    },
    getCityInfo: shippingInfo => {
        if (!shippingInfo?.firstName && !shippingInfo?.lastName) {
            return null;
        }
        return `${shippingInfo?.city ? shippingInfo?.city + ', ' : ''}${shippingInfo?.state ? shippingInfo?.state + ' ' : ''}${
            shippingInfo?.zipCode ?? ''
        }`;
    },
};

function ShippingAddressInfo({shippingInfo}) {
    const classes = useStyles();
    const {isDesktop} = useScreenSnap();

    return (
        <BSCCardBox p={0} className={classes.bgGrayLight}>
            {isDesktop && (
                <BSCCardBox className={classes.header}>
                    <BSCTypography style={{fontSize: 16, textAlign: 'center'}} label="Shipping Address" />
                </BSCCardBox>
            )}

            <Box p={2} className={classes.roundedBottoms}>
                <BSCTypography label={ShippingNormalizer.getName(shippingInfo)} />
                <BSCTypography label={ShippingNormalizer.getAddress(shippingInfo)} />
                <BSCTypography label={ShippingNormalizer.getCityInfo(shippingInfo)} />
            </Box>
        </BSCCardBox>
    );
}

function OrderInfo({selectedOrder}) {
    const classes = useStyles();
    const normalizer = useDateTimeNormalizer();

    return (
        <BSCCardBox p={0} className={classes.bgGrayLight}>
            <BSCCardBox className={classes.header}>
                <BSCTypography style={{fontSize: 16, textAlign: 'center'}} label="Order Details" />
            </BSCCardBox>
            <Box p={2} className={(classes.bgGrayLight, classes.roundedBottoms)}>
                <BSCGridTableRow
                    flip
                    col8={<BSCTypography align="right" label={normalizer.getDisplayDate(selectedOrder?.orderDate)} />}
                    col4={<BSCTypography label="Date:" />}
                />
                <BSCGridTableRow
                    flip
                    col8={<BSCTypography align="right" label={selectedOrder?.orderNo} />}
                    col4={<BSCTypography label="Order #:" />}
                />
                <BSCGridTableRow
                    flip
                    col8={<BSCTypography align="right" label={Normalizer.getOrderStatusDisplay(selectedOrder?.orderStatus)} />}
                    col4={<BSCTypography label="Status:" />}
                />
            </Box>
        </BSCCardBox>
    );
}

function FeedbackInfo({feedback, loading}) {
    const classes = useStyles();
    const {isDesktop} = useScreenSnap();

    return (
        <BSCCardBox p={0} style={{height: 143}} className={classes.bgGrayLight}>
            {isDesktop && (
                <BSCCardBox className={classes.header}>
                    <BSCTypography style={{fontSize: 16, textAlign: 'center'}} label="Feedback" />
                </BSCCardBox>
            )}
            <Box p={2} textAlign="center" style={isDesktop ? {paddingTop: 4} : {}}>
                {feedback && (
                    <>
                        <Rating name="simple-controlled" value={Number(feedback?.rating ?? 0)} readOnly />
                        <Box
                            style={{
                                background: '#E9EDF2',
                                padding: '4px 8px',
                                border: '1px solid #B8C2CC',
                                borderRadius: 4,
                            }}
                        >
                            {isDesktop && (
                                <BSCTypography
                                    size12
                                    style={
                                        feedback.comment !== '' && feedback.comment !== undefined
                                            ? {textAlign: 'left', textAlign: 'left'}
                                            : {textAlign: 'left', textAlign: 'center', color: '#888'}
                                    }
                                    maxCharacters={106}
                                    label={
                                        feedback.comment !== '' && feedback.comment !== undefined ? feedback?.comment : 'No Feedback Left'
                                    }
                                />
                            )}
                            {!isDesktop && <BSCTypography label={feedback?.comment} />}
                        </Box>
                    </>
                )}
                {!feedback && !loading && (
                    <BSCTypography size12 style={{textAlign: 'center', margin: '32px auto'}} label="No Feedback from Buyer" />
                )}
                {loading && (
                    <>
                        <Skeleton height="1rem" />
                        <Skeleton height="1rem" />
                    </>
                )}
            </Box>
        </BSCCardBox>
    );
}

function OrderTotal({order}) {
    const classes = useStyles();
    const {isDesktop} = useScreenSnap();
    const orderTotal = (order?.subTotal ?? 0) * 1 + (order?.shippingCost ?? 0) * 1;
    return (
        <BSCCardBox p={0} style={{borderRadius: 8}}>
            {isDesktop && <BSCHeaderBlock align="center" hasCustomRadius={true} label="Order Total" />}
            <Box p={2} className={classNames(classes.orderTotalContent, classes.bgGrayLight, classes.roundedBottoms)}>
                <BSCGridTableRow
                    col4={<BSCCurrency align="right" amount={order?.subTotal} />}
                    col8={<BSCTypography label="Item(s) Total:" />}
                />
                <BSCGridTableRow
                    col4={<BSCCurrency align="right" amount={order?.shippingCost} />}
                    col8={<BSCTypography label="Shipping:" />}
                />
                <BSCDivider />
                <BSCGridTableRow
                    col4={<BSCCurrency align="right" semibold amount={orderTotal} />}
                    col8={<BSCTypography label="Order Total:" />}
                />
                <BSCGridTableRow
                    col4={<BSCTypography align="right" color="error" label={`($${currencyFormatter(order?.stripePaymentFee ?? '0')})`} />}
                    col8={<BSCTypography label="Payment Processing:" />}
                />
                <BSCGridTableRow
                    col4={<BSCTypography align="right" color="error" label={`($${currencyFormatter(order?.sellerFee ?? '0')})`} />}
                    col8={<BSCTypography label="BSC Seller Fee:" />}
                />
                {order?.shipments !== undefined && order?.shipments.some(s => s.bscShipped ?? false) === true && (
                    <BSCGridTableRow
                        col4={<BSCTypography align="right" color="error" label={`($${currencyFormatter(order?.shippingCharge ?? '0')})`} />}
                        col8={<BSCTypography label="BSC Shipping:" />}
                    />
                )}
                <BSCGridTableRow
                    col4={<BSCTypography align="right" color="error" label={`($${currencyFormatter(order?.refundTotal ?? '0')})`} />}
                    col8={<BSCTypography label="Refunds:" />}
                />
                {/* <BSCGridTableRow col4={<BSCCurrency align="right" amount={order?.salesTax} />} col8={<BSCTypography label="Sales Tax" />} /> */}
                <BSCDivider />
                <BSCGridTableRow
                    col4={<BSCCurrency align="right" semibold amount={order?.sellerNetTotal} />}
                    col8={<BSCTypography label="Net Total:" />}
                />
            </Box>
        </BSCCardBox>
    );
}

function Refunds({refunds, reasons}) {
    const classes = useStyles();
    const dateNormalizer = useDateTimeNormalizer();
    const {isDesktop} = useScreenSnap();

    return (
        <BSCCardBox p={0}>
            {isDesktop && <BSCHeaderBlock label="Refund(s)" />}
            <Box
                p={2}
                style={{fontWeight: 400}}
                className={classNames(classes.orderTotalContent, classes.bgGrayLight, classes.roundedBottoms)}
            >
                {refunds &&
                    reasons &&
                    refunds.length > 0 &&
                    refunds.map((refund, index) => {
                        const reason = reasons.find(item => {
                            return item.key === refund.refundReason;
                        });
                        return (
                            <div key={index}>
                                <Grid container alignItems="center">
                                    <Grid item xs={8}>
                                        <BSCTypography label="Date:" />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <BSCTypography align="right" label={dateNormalizer.getDisplayDate(refund.transactionDate, true)} />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <BSCTypography label="Reason:" />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <BSCTypography align="right" label={reason ? reason.name : refund?.refundReason} />
                                    </Grid>

                                    <Grid item xs={8}>
                                        <BSCTypography label="Type:" />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <BSCTypography
                                            align="right"
                                            semibold
                                            label={refund?.refundType === 'partial' ? 'Partial' : 'Full'}
                                        />
                                    </Grid>

                                    <Grid item xs={8}>
                                        <BSCTypography label="Amount:" />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <BSCTypography align="right" label={`$${currencyFormatter(refund?.refundTotal ?? '0')}`} />
                                    </Grid>
                                </Grid>
                                {refunds?.length > 1 && index < refunds?.length - 1 && <BSCDivider />}
                            </div>
                        );
                    })}
                {!refunds || (refunds.length === 0 && <BSCTypography size12 align="center" label={'No refunds issued'} />)}
            </Box>
        </BSCCardBox>
    );
}

interface LocationState {
    state: {
        results: Order[];
        selectedIndex: number;
    };
}

function SellersOrderDetails() {
    const classes = useStyles();
    const history = useHistory();
    const {instance} = useMsal();
    const {apiAuthConfig} = useAuthState();
    let referenceApi = new ReferenceApi(apiAuthConfig);
    let orderApi = new OrderApi(apiAuthConfig);
    const location = useLocation();
    const {addToast} = useReduxToast();
    const authFunc = useAuthFunctions();
    const {isDesktop} = useScreenSnap();
    const {state: locationStateOrderDetails}: LocationState = useLocation();
    const [selectedIndex, setSelectedIndex] = useState(locationStateOrderDetails?.selectedIndex);
    const selectedOrderResult = locationStateOrderDetails?.results?.[selectedIndex];
    const selectedOrderId = selectedOrderResult?.orderId;

    const totalResults = locationStateOrderDetails?.results?.length;
    const [refresh, setRefreshFlag] = useState<boolean>(false); //it just needs to change to reload order
    const {data: refundReasons, loading: refundReasonsLoading} = useQuery(referenceApi.getRefundReasons, []);
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const getSellersOrderInstance = uuidv4();

    const [shippingInfo, setShippingInfo] = useState(null);
    const [feedback, setFeedback] = useState(null);
    const [shipment, setShipment] = useState(null);
    const [refundModalOpen, setRefundModalOpen] = useState<boolean>(false);
    const [togglePrintLabelModal, setTogglePrintLabelModal] = useState<boolean>(false);

    const handleNextOrder = useCallback(() => {
        setSelectedIndex(prevIndex => {
            const nextIndex = prevIndex + 1;
            if (nextIndex < totalResults) {
                return nextIndex;
            }

            return prevIndex;
        });
    }, [totalResults]);
    const handlePrevOrder = useCallback(() => {
        setSelectedIndex(prevIndex => {
            const nextIndex = prevIndex - 1;
            if (nextIndex >= 0) {
                return nextIndex;
            }

            return prevIndex;
        });
    }, []);

    const [refundedOrder, setRefundedOrder] = useState<OrderRefund | null>(null);
    const refundOrder = useCallback((orderId, request) => {
        const account = instance.getActiveAccount();
        if (account !== null) {
            const tokenRequest: SilentRequest = {
                account: account as AccountInfo | undefined,
                scopes: AUTH_REQUESTS.LOGIN.scopes,
            };
            instance
                .acquireTokenSilent(tokenRequest)
                .then(async response => {
                    if (response.accessToken) {
                        const apiConfig = {...apiAuthConfig, accessToken: response.accessToken};
                        try {
                            const orderApi = new OrderApi(apiConfig);
                            const response = await orderApi.refundOrderById(request, orderId);
                            if (response.data) {
                                setRefreshFlag(!refresh);
                                addToast({
                                    severity: 'success',
                                    message: 'Successfully issued refund.',
                                });
                                setRefundedOrder(response.data);
                            }
                        } catch (error) {
                            console.log(error);
                            addToast({
                                severity: 'error',
                                message: 'Failed to process Order refund.',
                                contextKey: 'Order Details',
                            });
                        }
                    }
                })
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .catch(error => {
                    addToast({
                        severity: 'error',
                        message: 'User must be Signed In.',
                        contextKey: 'Authentication',
                    });
                    setTimeout(() => {
                        authFunc.logout();
                    }, 5000);
                });
        }
    }, []);

    const handleRefundSubmitted = request => {
        setRefundModalOpen(false);
        request.subtotalRefund = request.subtotalRefund.toString().replace(',', '');
        request.shippingRefund = request.shippingRefund.toString().replace(',', '');
        request.orderId = selectedOrderId;
        refundOrder(selectedOrderId, request);
    };

    //Leaving all this in place in case we come up with a way to get around CORS for printing the pdf
    const [printSlip, setPrintSlip] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [printLabel, setPrintLabel] = useState(false);

    const handleShowPackingSlip = () => {
        setPrintSlip(true);
        setPrintLabel(false);

        setTimeout(() => {
            const css = '@page {size: letter portrait; margin: 5mm 20mm 20mm 20mm; }';
            const head = document.head || document.getElementsByTagName('head')[0];
            const style = document.createElement('style');

            style.media = 'print';
            style.appendChild(document.createTextNode(css));

            head.appendChild(style);
            window.print();
            head.removeChild(style);
            setPrintSlip(false);
            setRefreshFlag(!refresh);
        }, 50);
    };

    const handlePrintLabel = () => {
        setPrintLabel(true);
        setPrintSlip(false);
        setTogglePrintLabelModal(true);
    };

    const handleRefund = useCallback(() => {
        setRefundModalOpen(true);
    }, [selectedOrderId, selectedOrderResult?.total, refundOrder]);

    const [orderTotal, setOrderTotal] = useState(0.0);
    useEffect(() => {
        if (data && data.data) {
            setOrderTotal(parseFloat(data.data.subTotal) + parseFloat(data.data.shippingCost));
        }
    }, [data]);

    const orderIsCanceled = useMemo(() => {
        if (data?.data) {
            return Normalizer.getOrderStatusDisplay(data?.data?.orderStatus).toLowerCase() === 'cancelled';
        } else return false;
    }, [data]);

    useEffect(() => {
        const account = instance.getActiveAccount();
        if (account !== null && selectedIndex !== undefined) {
            const tokenRequest: SilentRequest = {
                account: account as AccountInfo | undefined,
                scopes: AUTH_REQUESTS.LOGIN.scopes,
            };
            instance
                .acquireTokenSilent(tokenRequest)
                .then(async response => {
                    if (response.accessToken) {
                        const apiConfig: Configuration = {...apiAuthConfig, accessToken: response.accessToken};
                        try {
                            setData(null);
                            setShipment(null);
                            setShippingInfo(null);
                            setFeedback(null);
                            referenceApi = new ReferenceApi(apiConfig);
                            orderApi = new OrderApi(apiConfig);
                            const response = await orderApi.getOrderById(selectedOrderId);
                            setData(response);
                            const responseShipment = response?.data?.shipments?.length > 0 ? response.data.shipments[0] : undefined;
                            setShipment(responseShipment);
                            const responseShippingInfo = response?.data.shippingAddress;
                            setShippingInfo(responseShippingInfo);
                            const responseFeedback = response?.data.feedback;
                            setFeedback(responseFeedback);
                            setLoading(false);
                            if (response.data.orderStatus !== 'cancelled') {
                                setOrderTotal(parseFloat(response.data.subTotal) + parseFloat(response.data.shippingCost));
                            }
                        } catch (error) {
                            console.log(error);
                            addToast({
                                severity: 'error',
                                message: 'Failed to get order details.',
                                contextKey: 'Order Details',
                            });
                        }
                    }
                })
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .catch(error => {
                    addToast({
                        severity: 'error',
                        message: 'User must be Signed In.',
                        contextKey: 'Authentication',
                    });
                    setTimeout(() => {
                        authFunc.logout();
                    }, 5000);
                });
        }
    }, [apiAuthConfig, instance, selectedIndex, refresh]);

    return (
        <BSCContainer variant="page">
            <OrderDetailsHeader
                selectedOrderId={selectedOrderId}
                selectedIndex={selectedIndex}
                totalResults={totalResults}
                onNextOrder={handleNextOrder}
                onPrevOrder={handlePrevOrder}
                printSlip={handleShowPackingSlip}
                onRefund={handleRefund}
                order={data?.data}
                orderIsCanceled={orderIsCanceled}
            />
            {refundedOrder && (
                <Alert severity="success" variant="filled">
                    Refunded Order
                </Alert>
            )}
            {isDesktop && (
                <Grid container xs={12} direction="row" justifyContent="space-between" className={classes.noPrint}>
                    {/* Left Column */}
                    <Grid item container xs={12} md={8} alignContent="flex-start">
                        <Grid item xs={12} md={6} className={[classes.marginTop, classes.paddingRight]}>
                            <ShippingAddressInfo shippingInfo={shippingInfo} />
                        </Grid>
                        <Grid item xs={12} md={6} className={[classes.marginTop, classes.paddingRight]}>
                            <OrderInfo selectedOrder={data?.data} />
                        </Grid>
                        {!orderIsCanceled && (
                            <Grid item xs={12} className={[classes.marginTop, classes.paddingRight]}>
                                <ShippingStatus
                                    orderId={selectedOrderId}
                                    order={data?.data}
                                    orderLoading={loading}
                                    shippingDetails={shipment}
                                    getSellersOrderInstance={getSellersOrderInstance}
                                    orderCost={orderTotal}
                                    refreshOrder={() => setRefreshFlag(!refresh)}
                                    handlePrintLabel={handlePrintLabel}
                                />
                            </Grid>
                        )}
                        <Grid item xs={12} className={[classes.marginTop, classes.paddingRight, classes.roundedBottoms]}>
                            <Cards data={data?.data.orderItems} loading={loading} />
                        </Grid>
                    </Grid>
                    {/* right Column */}
                    <Grid container item xs={12} md={4} direction="column" align="space-between">
                        <Grid item className={classes.marginTop}>
                            <FeedbackInfo feedback={feedback} loading={loading} />
                        </Grid>
                        <Grid item className={classes.marginTop}>
                            <OrderTotal order={data?.data} />
                        </Grid>
                        <Grid item className={classes.marginTop}>
                            <Refunds refunds={data?.data?.refunds} reasons={refundReasons?.data} />
                        </Grid>
                    </Grid>
                </Grid>
            )}
            {!isDesktop && (
                <Grid container xs={12} direction="row" justifyContent="space-between" className={classes.noPrint}>
                    <Grid item container xs={12} md={8} alignContent="flex-start">
                        <Grid item xs={12} md={6} className={[classes.marginTop, classes.paddingRight]}>
                            <OrderInfo selectedOrder={data?.data} />
                        </Grid>
                        <Grid item xs={12} md={6} className={[classes.marginTop, classes.paddingRight]}>
                            <Accordion square={false}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <BSCTypography size16>
                                        Order Total (
                                        {new Intl.NumberFormat('en-US', {
                                            style: 'currency',
                                            currency: 'USD',
                                        }).format(orderTotal)}
                                        )
                                    </BSCTypography>
                                </AccordionSummary>
                                <AccordionDetails style={{backgroundColor: '#DAE0E6'}}>
                                    <OrderTotal order={data?.data} />
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        <Grid item xs={12} md={6} className={[classes.marginTop, classes.paddingRight]}>
                            <Accordion square={false}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <BSCTypography size16>Shipping Address</BSCTypography>
                                </AccordionSummary>
                                <AccordionDetails style={{backgroundColor: '#DAE0E6'}}>
                                    <ShippingAddressInfo shippingInfo={shippingInfo} />
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        <Grid item xs={12} md={6} className={[classes.marginTop, classes.paddingRight]}>
                            <Accordion square={false}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <BSCTypography size16>Shipping Status</BSCTypography>
                                </AccordionSummary>
                                <AccordionDetails style={{backgroundColor: '#DAE0E6'}}>
                                    <ShippingStatus
                                        orderId={selectedOrderId}
                                        orderLoading={loading}
                                        shippingDetails={shipment}
                                        getSellersOrderInstance={getSellersOrderInstance}
                                        orderCost={orderTotal}
                                        hasTitle={false}
                                        handlePrintLabel={handlePrintLabel}
                                        refreshOrder={() => setRefreshFlag(!refresh)}
                                    />
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        <Grid item xs={12} className={[classes.marginTop, classes.paddingRight, classes.roundedBottoms]}>
                            <Accordion square={false}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <BSCTypography size16>Cards</BSCTypography>
                                </AccordionSummary>
                                <AccordionDetails style={{backgroundColor: '#DAE0E6'}}>
                                    <Cards hasTitle={false} data={data?.data.orderItems} loading={loading} />
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        <Grid item xs={12} className={[classes.marginTop, classes.paddingRight, classes.roundedBottoms]}>
                            <Accordion square={false}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <BSCTypography size16>Feedback</BSCTypography>
                                </AccordionSummary>
                                <AccordionDetails style={{backgroundColor: '#DAE0E6'}}>
                                    <FeedbackInfo feedback={feedback} loading={loading} />
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        <Grid item xs={12} className={[classes.marginTop, classes.paddingRight, classes.roundedBottoms]}>
                            <Accordion square={false}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <BSCTypography size16>Refund(s)</BSCTypography>
                                </AccordionSummary>
                                <AccordionDetails style={{backgroundColor: '#DAE0E6'}}>
                                    <Refunds refunds={data?.data?.refunds} reasons={refundReasons?.data} />
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    </Grid>
                </Grid>
            )}

            <div className={printSlip ? classes.Printable : classes.hidden}>
                <PackingSlip order={data?.data} />
            </div>

            <OrderRefundModal
                open={refundModalOpen}
                onClose={() => setRefundModalOpen(false)}
                itemAmount={data?.data?.subtotalRefundable ? currencyFormatter(data?.data?.subtotalRefundable) : '0.00'}
                shippingAmount={data?.data?.shippingRefundable ? currencyFormatter(data?.data?.shippingRefundable) : '0.00'}
                fullRefundAmount={data?.data?.refundTotal}
                onOrderRefundSubmitted={handleRefundSubmitted}
                allowFullrefund={data?.data?.refunds.length === 0}
                refundReasons={refundReasons?.data}
                refundReasonsLoading={refundReasonsLoading}
            ></OrderRefundModal>

            <PrintLabelModal
                open={togglePrintLabelModal}
                onClose={() => {
                    setTogglePrintLabelModal(false);
                    setPrintLabel(false);
                    setRefreshFlag(!refresh);
                }}
                printLabelImage={shipment?.shippingLabel?.label_download?.href}
            ></PrintLabelModal>
        </BSCContainer>
    );
}

export default SellersOrderDetails;
