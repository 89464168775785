/* eslint-disable prettier/prettier */
import {useEffect, useState} from 'react';
import SearchShopResultsClient from '../../../../client/search/shop/results/searchShopResultsClient';
import useSearch from './useSearch';

const useInfiniteSearch = (pageNumber, selectedSellerId) => {
    const {cleanedSearchQuery} = useSearch();
    const [infiniteResults, setInfiniteResults] = useState([]);
    const [infiniteResultsTotal, setInfiniteResultsTotal] = useState(0);
    useEffect(() => {
        if (localStorage.getItem('pwaSettings')) {
            delete cleanedSearchQuery['p'];
            cleanedSearchQuery['p'] = pageNumber.toString();
            let isSameAsPreviousSearch = false;
            if (localStorage.getItem('cleanedSearchQuery') !== null) {
                const cleanedSearchQueryString = localStorage.getItem('cleanedSearchQuery');
                isSameAsPreviousSearch = cleanedSearchQueryString === JSON.stringify(cleanedSearchQuery);
                localStorage.removeItem('cleanedSearchQuery');
            }
            if (!isSameAsPreviousSearch) {
                if (pageNumber === 0) {
                    setInfiniteResults([]);
                }
                let currentInfiniteResultsTotal = 0;
                if (localStorage.getItem('hasVisitedProductPage') !== null) {
                    localStorage.removeItem('hasVisitedProductPage');
                    if (localStorage.getItem('infiniteResultsTotal') !== null) {
                        currentInfiniteResultsTotal = parseInt(localStorage.getItem('infiniteResultsTotal'));
                        localStorage.removeItem('infiniteResultsTotal');
                    }
                } else {
                    localStorage.removeItem('infiniteResultsTotal');
                }

                console.log('>>>', cleanedSearchQuery);
                SearchShopResultsClient.marketPlaceSearch({
                    terms: cleanedSearchQuery,
                    sellerKey: selectedSellerId,
                    resultsPerPage: currentInfiniteResultsTotal > 0 ? currentInfiniteResultsTotal : 50,
                })
                    .then(infiniteSearchResults => {
                        if (pageNumber === 0) {
                            setInfiniteResults(infiniteSearchResults.data.results);
                        } else {
                            setInfiniteResults(prevResults => [
                                ...new Set([...prevResults, ...(infiniteSearchResults.data.results || [])]),
                            ]);
                        }
                        setInfiniteResultsTotal(infiniteSearchResults.data.totalResults);
                        localStorage.setItem(
                            'infiniteResultsTotal',
                            JSON.stringify(currentInfiniteResultsTotal + infiniteSearchResults.data.results.length)
                        );
                        localStorage.setItem('cleanedSearchQuery', JSON.stringify(cleanedSearchQuery));
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        }
    }, [pageNumber, cleanedSearchQuery, selectedSellerId]);

    return {infiniteResults, infiniteResultsTotal};
};

export default useInfiniteSearch;
