import {Grid} from '@material-ui/core';
import {makeStyles, Theme} from '@material-ui/core/styles';
// import NotificationsIcon from '@material-ui/icons/Notifications';
import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import BSCTypography from '../../../../features/common/bscTypography/bscTypography';
import * as constants from '../../../../shared/constants';
import './styles.scss';

const useStyles = makeStyles((theme: Theme) => ({
    notificationsWrapper: {
        paddingTop: '36%',
    },
    selectionWrapper: {
        backgroundColor: theme.palette.common.white,
        padding: 8,
        marginBottom: 6,
    },
    emptySelectionWrapper: {
        height: 64,
        backgroundColor: 'transparent',
        marginBottom: 6,
    },
    largeIcon: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.secondary.main,
        padding: 8,
        '& svg': {
            height: 32,
            width: 32,
        },
    },
}));

function PWANotifications() {
    const classes = useStyles();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({
            type: constants.PWA_NAVBAR_ACTIVE_TAB,
            payload: {
                pwaNavbarActive: 3,
            },
        });
    }, []);

    return (
        <>
            <div className={classes.notificationsWrapper}>
                <Grid container>
                    <Grid item xs={12}>
                        <Grid container direction="column" justifyContent="center" alignItems="center">
                            <span className="material-icons" style={{fontSize: 240}}>
                                notifications
                            </span>
                            <BSCTypography label="Coming Soon..." style={{fontSize: 28, fontWeight: 700}} />
                            <BSCTypography label="Notifications Feed!" style={{fontSize: 28, fontWeight: 700}} />
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}

export default PWANotifications;
