/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-key */
/* eslint-disable prettier/prettier */
import {Link} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import {makeStyles, Theme} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import PhotoCameraOutlinedIcon from '@material-ui/icons/PhotoCameraOutlined';
import Alert from '@material-ui/lab/Alert';
import classNames from 'classnames';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import {useCardNormalizer} from '../../../../client/common/card/cardNormalizer';
import {CartItem, Product} from '../../../../client/openapitools/marketplace';
import BSCCurrency from '../../../../features/common/bscCurrency/bscCurrency';
import BSCSelectSplitButton from '../../../../features/common/bscSelectSplitButton/bscSelectSplitButton';
import BSCTypography from '../../../../features/common/bscTypography/bscTypography';
import BSCTableRowSkeleton from '../../../../features/common/skeletons/BSCTableRowSkeleton';
import useScreenSnap from '../../../../packages/core/src/hooks/useScreenSnap';
import {currencyFormatter, getFormattedNumberWithK, getFormattedNumberWithM} from '../../../../packages/core/src/util/util';
import {RootState} from '../../../../redux/reducers';
import * as constants from '../../../../shared/constants';
import CardConditions from '../../../sellersLocker/pages/sellYourCard/components/CardConditions';

const useStyles = makeStyles((theme: Theme) => ({
    tableRow: {
        '&:hover': {
            background: '#DAE0E6 !important',
            cursor: 'pointer',
        },
    },
    table: {
        // minWidth: 650,
        ['& > thead']: {
            background: theme.palette.secondary.main,
            color: theme.palette.common.white,
        },
        ['& > thead th']: {
            fontSize: '16px',
            color: theme.palette.common.white,
            textTransform: 'capitalize',
            padding: theme.spacing(1),
            '&:last-child': {
                padding: theme.spacing(1, 0),
            },
            [theme.breakpoints.down('xs')]: {
                padding: theme.spacing(0.5, 0.25),
            },
        },
        ['& td']: {
            fontSize: '14px',
            [theme.breakpoints.down('xs')]: {
                fontSize: '13px',
                padding: theme.spacing(1, 0.25),
            },
            padding: '8px !important',
        },
        ['& tr']: {
            transition: '.25s ease',
            borderBottom: '2px solid transparent',
        },
        ['& tbody tr:hover']: {
            boxShadow: 'inset 0px -4px 4px rgba(24, 32, 38, 0.5)',
            background: '#DAE0E6 !important',
            // borderLeft: `4px solid ${theme.palette.primary.main}`,
            cursor: 'pointer',
        },
    },
    selectedRow: {
        boxShadow: 'inset 0px -4px 4px rgba(24, 32, 38, 0.5)',
        background: '#B8C2CC',
        borderLeft: `4px solid ${theme.palette.primary.main}`,
        cursor: 'pointer',
    },
    // selectedFoldableRow: {
    //     background: '#B8C2CC',
    //     cursor: 'pointer',
    // },
    // foldableRowsExpanded: {
    //     background: '#fff',
    // },
    tableSmall: {
        padding: theme.spacing(1),
        '&:last-child': {
            padding: theme.spacing(1, 0),
        },
        ['& td']: {},
    },
    tableContainer: {
        borderRadius: 0,
    },
    stickyWrapper: {
        height: '46vh',
    },
    stickyWrapperMobile: {
        height: '40vh',
        overflowY: 'auto',
    },
    stickyWrapperPWA: {
        height: '310px',
        overflowY: 'auto',
    },
    tableCellHeader: {
        background: '#182026',
    },
    tableCellHeaderMobile: {
        background: '#182026',
        fontSize: '14px !important',
    },
    sellerInfo: {
        fontSize: '10px',
        [theme.breakpoints.up('sm')]: {
            fontWeight: 400,
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '9px',
        },
    },
    hideOnMobile: {
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    hideOnDesktop: {
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    price: {
        fontSize: '14px',
    },
    shipping: {
        fontSize: '10px',
        [theme.breakpoints.up('sm')]: {
            fontWeight: 400,
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '9px',
        },
    },
    headingEmphasis: {
        fontSize: 18,
    },
    collapseWrapper: {
        padding: theme.spacing(0, 2),
    },
    keyBoardArrow: {
        transition: '.5s ease',
        transform: 'scale(2)',
    },
    keyboardArrowFlipped: {
        transform: 'rotate(-180deg) scale(2)',
    },
    searchSize: {
        fontSize: '1.25rem',
    },
    box: {
        padding: theme.spacing(0.5, 0),
        '& .MuiMenuItem-root': {
            paddingTop: '0px',
            paddingBottom: '0px',
        },
    },
    boxMobile: {
        padding: theme.spacing(0.5, 0),
        '& .MuiMenuItem-root': {
            paddingTop: '0px',
            paddingBottom: '0px',
            minHeight: '36px !important',
        },
    },
    catHeader: {
        fontSize: theme.typography.subtitle1.fontSize,
    },
    catMenuItem: {
        display: 'flex',
        justifyContent: 'space-between',
    },
}));

const FoldableComponent = ({listings, index, listing, selected, onClick, onFoldableQtyChange, isVintage}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const [qty, setQty] = useState(0);
    const condition = useCardNormalizer();
    const {isBelowTablet, isMobile} = useScreenSnap();
    const tableCellClasses = {sizeSmall: classes.tableSmall};
    const setLinkableSeller = (sellerId, sellerName) => {
        dispatch({
            type: constants.SELLER_STOREFRONT_SELECTED,
            payload: {sellerId: sellerId, sellerName: sellerName},
        });
    };
    useEffect(() => {
        if (!selected) {
            setQty(0);
            handleFoldableQtyChange(0, null);
        }
    }, [selected]);

    const [formattedSellerName, setFormattedSellerName] = useState(null);
    useEffect(() => {
        if (listing.seller.sellerProfile.sellerStoreName?.length > 16) {
            setFormattedSellerName(listing.seller.sellerProfile.sellerStoreName.slice(0, 16) + '...');
        } else {
            setFormattedSellerName(listing.seller.sellerProfile.sellerStoreName);
        }
    }, [listing.seller.sellerProfile.sellerStoreName]);

    const [rawShippingTierCost, setRawShippingTierCost] = useState(0.0);
    const [gradedShippingTierCost, setGradedShippingTierCost] = useState(0.0);
    useEffect(() => {
        if (listing.seller.sellerProfile.shippingSettings?.length > 0) {
            const rawShippingTier = listing.seller.sellerProfile.shippingSettings.find(
                shippingSetting => shippingSetting.tierCode === 'XS'
            );
            setRawShippingTierCost(rawShippingTier?.rate);
            const gradedShippingTier = listing.seller.sellerProfile.shippingSettings.find(
                shippingSetting => shippingSetting.tierCode === 'G'
            );
            setGradedShippingTierCost(gradedShippingTier?.rate);
        }
    }, [listing.seller.sellerProfile.shippingSettings]);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [topRowIndex, setTopRowIndex] = useState(-1);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [foldableQuantity, setFoldableQuantity] = useState(0);
    const [hasSellerImage, setHasSellerImage] = useState(false);
    const [foldableListings, setFoldableListings] = useState([]);
    const handleFoldableQtyChange = (num, cardId) => {
        setQty(num);
        const sortedFoldableCards = foldableListings.sort((a, b) => {
            if (a.sellerImgBack > b.sellerImgBack) return -1;
            else if (a.sellerImgBack < b.sellerImgBack) return 1;
            else return 0;
        });
        const foldableCartEntries = [];
        let buyerSelectedQty = num;
        for (let x = 0; x < sortedFoldableCards.length; x++) {
            if (sortedFoldableCards[x].cardId === cardId && sortedFoldableCards[x].availableQuantity > 0) {
                // 1. Look at available quantity of the card
                // 2. If the number selected < available quantity, add the card to the foldableCartEntries and drop out of loop
                // 3. If the number selected === available quantity, add the card to the foldableCartEntries and drop out of loop
                // 4. If the number selected > available quantity, add the card to the foldableCartEntries,
                //                                                 subtract the current card's available quantity from the number selected,
                //                                                 and continue to the next card
                //
                if (buyerSelectedQty < sortedFoldableCards[x].availableQuantity) {
                    foldableCartEntries.push({
                        productId: sortedFoldableCards[x].productId,
                        quantity: buyerSelectedQty,
                        offers: sortedFoldableCards[x].seller.sellerProfile.offers,
                        sellerId: sortedFoldableCards[x].seller.id,
                    });
                    break;
                }
                if (buyerSelectedQty === sortedFoldableCards[x].availableQuantity) {
                    foldableCartEntries.push({
                        productId: sortedFoldableCards[x].productId,
                        quantity: buyerSelectedQty,
                        offers: sortedFoldableCards[x].seller.sellerProfile.offers,
                        sellerId: sortedFoldableCards[x].seller.id,
                    });
                    break;
                }
                if (buyerSelectedQty > sortedFoldableCards[x].availableQuantity) {
                    foldableCartEntries.push({
                        productId: sortedFoldableCards[x].productId,
                        quantity: sortedFoldableCards[x].availableQuantity,
                        offers: sortedFoldableCards[x].seller.sellerProfile.offers,
                        sellerId: sortedFoldableCards[x].seller.id,
                    });
                    buyerSelectedQty = buyerSelectedQty - sortedFoldableCards[x].availableQuantity;
                }
            }
        }
        onFoldableQtyChange(foldableCartEntries);
    };
    useEffect(() => {
        if (listings && listing && index) {
            const filteredCards = listings.filter(
                listingsElement =>
                    listingsElement.condition === listing.condition &&
                    listingsElement.price === listing.price &&
                    listingsElement.seller.id === listing.seller.id
            );
            const foldableCards = filteredCards.sort((a, b) => {
                if (a.sellerImgBack > b.sellerImgBack) return -1;
                else if (a.sellerImgBack < b.sellerImgBack) return 1;
                else return 0;
            });
            setFoldableListings(foldableCards);
            let topIndex = -1;
            listings.forEach((listingsElement, ndx) => {
                if (
                    listingsElement.condition === listing.condition &&
                    listingsElement.price === listing.price &&
                    listingsElement.seller.id === listing.seller.id
                ) {
                    topIndex = ndx;
                }
            });
            setTopRowIndex(topIndex);
            const foldableQuantity = listings.reduce((acc, listingsElement) => {
                if (
                    listingsElement.condition === listing.condition &&
                    listingsElement.price === listing.price &&
                    listingsElement.seller.id === listing.seller.id
                ) {
                    return acc + listingsElement.availableQuantity;
                }
                return acc;
            }, 0);
            setFoldableQuantity(foldableQuantity);
            setHasSellerImage(foldableCards.some(foldableCard => foldableCard.sellerImgFront.indexOf('/prod', '/product', '/card') > -1));
        }
    }, [listings, listing, index]);

    return (
        <>
            {index === topRowIndex && (
                <TableRow
                    className={classNames({
                        [classes.selectedRow]: selected,
                    })}
                    hover
                    onClick={() => {
                        handleFoldableQtyChange(1, foldableListings[0].cardId);
                        onClick(foldableListings[0]);
                    }}
                    key={listing.productId}
                >
                    {hasSellerImage === false && (
                        <TableCell classes={tableCellClasses} align="center">
                            <BSCTypography className={classes.shipping} label={''} />
                        </TableCell>
                    )}
                    {hasSellerImage && (
                        <TableCell classes={tableCellClasses} align="right" style={isMobile ? {padding: 6, width: 16} : {padding: 1}}>
                            <PhotoCameraOutlinedIcon
                                style={
                                    isMobile
                                        ? {color: '#666', width: 16, height: 16, marginTop: 6}
                                        : {color: '#666', width: 24, height: 24, marginTop: 6}
                                }
                            />
                        </TableCell>
                    )}

                    <TableCell classes={tableCellClasses} align="center">
                        {selected === true && (
                            <Link
                                href="#"
                                onClick={() =>
                                    setLinkableSeller(
                                        listing?.seller?.sellerProfile?.userId,
                                        listing?.seller?.sellerProfile?.sellerStoreName
                                    )
                                }
                                style={{
                                    textDecoration: 'underline',
                                    color: '#000',
                                    fontWeight: 'bold',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}
                            >
                                {isMobile || isBelowTablet ? formattedSellerName : listing?.seller?.sellerProfile?.sellerStoreName}
                            </Link>
                        )}
                        {selected === false && (
                            <div
                                style={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}
                            >
                                {isMobile || isBelowTablet ? formattedSellerName : listing?.seller?.sellerProfile?.sellerStoreName}
                            </div>
                        )}
                        {listing?.seller?.sellerProfile?.salesNumber === 0 && (
                            <BSCTypography className={classes.sellerInfo} label={'New Seller'} />
                        )}

                        {listing?.seller?.sellerProfile?.salesNumber > 0 && (
                            <BSCTypography
                                className={classes.sellerInfo}
                                label={`${parseFloat(listing?.seller?.sellerProfile?.aggregateRating)
                                    .toFixed(1)
                                    .replace(/([0-9]+(\.[0-9]+[1-9])?)(\.?0+$)/, '$1')}% (${
                                    listing?.seller?.sellerProfile?.totalQuantity
                                } Cards Sold)`}
                            />
                        )}
                    </TableCell>
                    <TableCell classes={tableCellClasses} align="center" style={isMobile ? {minWidth: 80} : {}}>
                        {listing.productType === 'raw' ? condition.getRawCondition(listing, isVintage) : condition.getGrade(listing)}
                    </TableCell>
                    <TableCell classes={tableCellClasses} align="center">
                        <BSCCurrency className={classes.price} amount={listing.price} />
                        {listing.productType === 'raw' && rawShippingTierCost === 0.0 && (
                            <BSCTypography className={classes.shipping} label="Free Shipping" />
                        )}
                        {listing.productType === 'raw' && rawShippingTierCost > 0.0 && (
                            <BSCTypography
                                className={classes.shipping}
                                style={isMobile ? {minWidth: 80} : {}}
                                label={`+ $${currencyFormatter(rawShippingTierCost)} Shipping`}
                            />
                        )}
                        {listing.productType === 'graded' && gradedShippingTierCost === 0.0 && (
                            <BSCTypography className={classes.shipping} label="Free Shipping" />
                        )}
                        {listing.productType === 'graded' && gradedShippingTierCost > 0.0 && (
                            <BSCTypography
                                className={classes.shipping}
                                style={isMobile ? {minWidth: 80} : {}}
                                label={`+ $${currencyFormatter(gradedShippingTierCost)} Shipping`}
                            />
                        )}
                    </TableCell>
                    <TableCell
                        classes={tableCellClasses}
                        align="center"
                        style={isMobile ? {width: '60px !important', paddingRight: 8} : {}}
                    >
                        <BSCSelectSplitButton
                            //TODO: Should come back and find a better way
                            leftButtonLabel={qty === 0 ? '-' : qty}
                            rightButtonLabel={`of ${String(foldableQuantity)}`}
                        >
                            {Array.from(Array(foldableQuantity ?? 0), (_, i) => (
                                <MenuItem
                                    selected={qty === i + 1}
                                    key={i + 1}
                                    value={i + 1}
                                    onClick={e => {
                                        handleFoldableQtyChange(i + 1, listing.cardId);
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }}
                                >
                                    {i + 1}
                                </MenuItem>
                            ))}
                        </BSCSelectSplitButton>
                    </TableCell>
                </TableRow>
            )}
        </>
    );
};

const RowComponent = ({listing, selected, onClick, onQtyChange, isVintage}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const [qty, setQty] = useState(0);
    const condition = useCardNormalizer();
    const {isBelowTablet, isMobile} = useScreenSnap();
    const tableCellClasses = {sizeSmall: classes.tableSmall};
    const handleQtyChange = num => {
        setQty(num);
        const quantityChangeGroup = [];
        quantityChangeGroup.push({
            productId: listing.productId,
            quantity: num,
            offers: listing?.seller?.sellerProfile?.offers,
            sellerId: listing?.seller?.id,
        });
        onQtyChange(quantityChangeGroup);
    };
    const setLinkableSeller = (sellerId, sellerName) => {
        dispatch({
            type: constants.SELLER_STOREFRONT_SELECTED,
            payload: {sellerId: sellerId, sellerName: sellerName},
        });
        history.push('/search?inStock=true&p=0');
    };
    useEffect(() => {
        if (!selected) {
            setQty(0);
            handleQtyChange(0);
        }
    }, [selected]);

    const [formattedSellerName, setFormattedSellerName] = useState(null);
    useEffect(() => {
        if (listing.seller.sellerProfile.sellerStoreName?.length > 16) {
            setFormattedSellerName(listing.seller.sellerProfile.sellerStoreName.slice(0, 16) + '...');
        } else {
            setFormattedSellerName(listing.seller.sellerProfile.sellerStoreName);
        }
    }, [listing.seller.sellerProfile.sellerStoreName]);

    const [rawShippingTierCost, setRawShippingTierCost] = useState(0.0);
    const [gradedShippingTierCost, setGradedShippingTierCost] = useState(0.0);
    useEffect(() => {
        if (listing.seller.sellerProfile.shippingSettings?.length > 0) {
            const rawShippingTier = listing.seller.sellerProfile.shippingSettings.find(
                shippingSetting => shippingSetting.tierCode === 'XS'
            );
            setRawShippingTierCost(rawShippingTier?.rate);
            const gradedShippingTier = listing.seller.sellerProfile.shippingSettings.find(
                shippingSetting => shippingSetting.tierCode === 'G'
            );
            setGradedShippingTierCost(gradedShippingTier?.rate);
        }
    }, [listing.seller.sellerProfile.shippingSettings]);

    return (
        <TableRow
            className={classNames({
                [classes.selectedRow]: selected,
            })}
            hover
            onClick={() => {
                handleQtyChange(1);
                onClick(listing);
            }}
            key={listing.productId}
        >
            {listing.sellerImgFront.indexOf('/prod', '/product', '/card') === -1 && (
                <TableCell classes={tableCellClasses} align="center">
                    <BSCTypography className={classes.shipping} label={''} />
                </TableCell>
            )}
            {listing.sellerImgFront.indexOf('/prod', '/product', '/card') > -1 && (
                <TableCell classes={tableCellClasses} align="right" style={isMobile ? {padding: 6, width: 16} : {padding: 1}}>
                    <PhotoCameraOutlinedIcon
                        style={
                            isMobile
                                ? {color: '#666', width: 16, height: 16, marginTop: 6}
                                : {color: '#666', width: 24, height: 24, marginTop: 6}
                        }
                    />
                </TableCell>
            )}

            <TableCell classes={tableCellClasses} align="center">
                {selected === true && (
                    <Link
                        href="#"
                        onClick={() =>
                            setLinkableSeller(listing?.seller?.sellerProfile?.userId, listing?.seller?.sellerProfile?.sellerStoreName)
                        }
                        style={{
                            textDecoration: 'underline',
                            color: '#000',
                            fontWeight: 'bold',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {isMobile || isBelowTablet ? formattedSellerName : listing?.seller?.sellerProfile?.sellerStoreName}
                    </Link>
                )}
                {selected === false && (
                    <div style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                        {isMobile || isBelowTablet ? formattedSellerName : listing?.seller?.sellerProfile?.sellerStoreName}
                    </div>
                )}
                {listing?.seller?.sellerProfile?.salesNumber === 0 && <BSCTypography className={classes.sellerInfo} label={'New Seller'} />}

                {listing?.seller?.sellerProfile?.salesNumber > 0 && (
                    <BSCTypography
                        className={classes.sellerInfo}
                        label={`${parseFloat(listing?.seller?.sellerProfile?.aggregateRating)
                            .toFixed(1)
                            .replace(/([0-9]+(\.[0-9]+[1-9])?)(\.?0+$)/, '$1')}% (${
                            listing?.seller?.sellerProfile?.totalQuantity
                        } Cards Sold)`}
                    />
                )}
            </TableCell>
            <TableCell classes={tableCellClasses} align="center" style={isMobile ? {minWidth: 80} : {}}>
                {listing.productType === 'raw' ? condition.getRawCondition(listing, isVintage) : condition.getGrade(listing)}
            </TableCell>
            <TableCell classes={tableCellClasses} align="center">
                <BSCCurrency className={classes.price} amount={listing.price} />
                {listing.productType === 'raw' && rawShippingTierCost === 0.0 && (
                    <BSCTypography className={classes.shipping} label="Free Shipping" />
                )}
                {listing.productType === 'raw' && rawShippingTierCost > 0.0 && (
                    <BSCTypography
                        className={classes.shipping}
                        style={isMobile ? {minWidth: 80} : {}}
                        label={`+ $${currencyFormatter(rawShippingTierCost)} Shipping`}
                    />
                )}
                {listing.productType === 'graded' && gradedShippingTierCost === 0.0 && (
                    <BSCTypography className={classes.shipping} label="Free Shipping" />
                )}
                {listing.productType === 'graded' && gradedShippingTierCost > 0.0 && (
                    <BSCTypography
                        className={classes.shipping}
                        style={isMobile ? {minWidth: 80} : {}}
                        label={`+ $${currencyFormatter(gradedShippingTierCost)} Shipping`}
                    />
                )}
            </TableCell>
            <TableCell classes={tableCellClasses} align="center" style={isMobile ? {width: '60px !important', paddingRight: 8} : {}}>
                <BSCSelectSplitButton
                    //TODO: Should come back and find a better way
                    leftButtonLabel={qty === 0 ? '-' : qty}
                    rightButtonLabel={`of ${String(listing.availableQuantity)}`}
                >
                    {Array.from(Array(listing.availableQuantity ?? 0), (_, i) => (
                        <MenuItem
                            selected={qty === i + 1}
                            key={i + 1}
                            value={i + 1}
                            onClick={e => {
                                handleQtyChange(i + 1);
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                        >
                            {i + 1}
                        </MenuItem>
                    ))}
                </BSCSelectSplitButton>
            </TableCell>
        </TableRow>
    );
};

interface ProductListingsTableProps {
    onProductSelect: (qtyChangeGroup) => void;
    onFoldableProductsSelect: (productIds: string[], quantity: number, offers: boolean, sellerId: string) => void;
    listings: Product[];
    loading: boolean;
    selectedListing: CartItem;
    type: number;
    cardYear: string;
}

function ProductListingTable({onProductSelect, onFoldableProductsSelect, listings, loading, type, cardYear}: ProductListingsTableProps) {
    const dispatch = useDispatch();
    const [filteredListings, setFilteredListings] = useState(null);
    const classes = useStyles();
    const {isMobile, isBelowTablet} = useScreenSnap();
    const marketplaceState = useSelector((state: RootState) => state.marketplace);
    const [selectedProductId, setSelectedProductId] = useState<string>();
    const [selectedCardId, setSelectedCardId] = useState<string>();
    const [pwaOS, setPwaOS] = useState(null);
    const handleDefaultRowClick = (listing: Product) => {
        if (listing?.sellerImgFront) {
            dispatch({
                type: constants.SELLER_SELECTED,
                payload: {
                    sellerName: listing.seller?.sellerProfile?.sellerStoreName,
                    imageFront: listing.sellerImgFront,
                    imageBack: listing.sellerImgBack,
                    isVintage: isVintage,
                },
            });
        }
        setSelectedCardId(listing.cardId);
        setSelectedProductId(listing.productId);
    };

    const handleQuantityChange = qtyChangeGroup => {
        if (qtyChangeGroup && qtyChangeGroup.length > 0) {
            const {productId, quantity, offers, sellerId} = qtyChangeGroup[0];
            if (quantity > 0) {
                onProductSelect(qtyChangeGroup);
            }
        }
    };

    useEffect(() => {
        if (marketplaceState.pwaSettings.pwaOS !== '') {
            setPwaOS(marketplaceState.pwaSettings.pwaOS);
        }
    }, [marketplaceState]);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [isVintage, setVintage] = useState(false);
    useEffect(() => {
        if (Number(cardYear) <= 1980) {
            setVintage(true);
        } else {
            setVintage(false);
        }
    }, [cardYear]);

    let sortedListings = [];
    const scanForFoldableContenders = listing => {
        return sortedListings.filter(sortedListing => {
            return (
                sortedListing.condition === listing.condition &&
                sortedListing.price === listing.price &&
                sortedListing.seller.id === listing.seller.id
            );
        });
    };

    const [listingsLoading, setListingsLoading] = useState(true);
    useEffect(() => {
        if (listings) {
            setListingsLoading(true);
            sortedListings = listings.sort((a, b) => {
                if (a.condition < b.condition) return -1;
                else if (a.condition > b.condition) return 1;
                else {
                    if (a.seller.id < b.seller.id) return -1;
                    else if (a.seller.id > b.seller.id) return 1;
                    else {
                        if (a.price < b.price) return -1;
                        else if (a.price > b.price) return 1;
                        else return 0;
                    }
                }
            });

            for (let i = 0; i < sortedListings.length; i++) {
                if (sortedListings.length >= 1 && isVintage === false) {
                    const potentialFoldableContenders = scanForFoldableContenders(sortedListings[i]);
                    if (potentialFoldableContenders.length > 1) {
                        sortedListings[i]['isFoldable'] = true;
                    } else {
                        sortedListings[i]['isFoldable'] = false;
                    }
                } else {
                    sortedListings[i]['isFoldable'] = false;
                }
            }

            const foldedListings = sortedListings.sort((a, b) => {
                if (a.totalCost < b.totalCost) return -1;
                else if (a.totalCost > b.totalCost) return 1;
                else {
                    if (a.seller.sellerProfile.aggregateRating > b.seller.sellerProfile.aggregateRating) return -1;
                    else if (a.seller.sellerProfile.aggregateRating < b.seller.sellerProfile.aggregateRating) return 1;
                    else {
                        if (a.seller.sellerProfile.totalQuantity > b.seller.sellerProfile.totalQuantity) return -1;
                        else if (a.seller.sellerProfile.totalQuantity < b.seller.sellerProfile.totalQuantity) return 1;
                        else {
                            if (a.seller.sellerProfile.sellerStoreName < b.seller.sellerProfile.sellerStoreName) return -1;
                            else if (a.seller.sellerProfile.sellerStoreName > b.seller.sellerProfile.sellerStoreName) return 1;
                            else {
                                if (a.condition < b.condition) return -1;
                                else if (a.condition > b.condition) return 1;
                                else {
                                    if (a.isFoldable < b.isFoldable) return -1;
                                    else if (a.isFoldable > b.isFoldable) return 1;
                                    else return 0;
                                }
                            }
                        }
                    }
                }
            });

            const tempListings = foldedListings.filter(
                listing => listing.price !== '0.00' && (listing.condition || (listing.grade && listing.gradingCompany))
            );
            tempListings.forEach(listing => {
                if (
                    parseInt(listing.seller.sellerProfile.totalQuantity) >= 1000 &&
                    parseInt(listing.seller.sellerProfile.totalQuantity) < 100000
                ) {
                    listing.seller.sellerProfile.totalQuantity = listing?.seller?.sellerProfile?.totalQuantity
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                } else if (
                    parseInt(listing.seller.sellerProfile.totalQuantity) >= 100000 &&
                    parseInt(listing.seller.sellerProfile.totalQuantity) < 1000000
                ) {
                    listing.seller.sellerProfile.totalQuantity = getFormattedNumberWithK(
                        parseInt(listing.seller.sellerProfile.totalQuantity)
                    );
                } else if (parseInt(listing.seller.sellerProfile.totalQuantity) >= 1000000) {
                    listing.seller.sellerProfile.totalQuantity = getFormattedNumberWithM(
                        parseInt(listing.seller.sellerProfile.totalQuantity)
                    );
                }
            });
            setFilteredListings(tempListings);
            setListingsLoading(false);
        }
    }, [listings]);

    return (
        <>
            <TableContainer component={Paper} square={false} elevation={0} className={classes.tableContainer} id="desktopScrollTag">
                <div
                    className={
                        (isBelowTablet || isMobile) && pwaOS === null
                            ? classes.stickyWrapperMobile
                            : pwaOS !== null
                              ? classes.stickyWrapperPWA
                              : classes.stickyWrapper
                    }
                    id="mobileScrollTag"
                >
                    <Table stickyHeader className={classes.table} size="small" aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell className={isMobile ? classes.tableCellHeaderMobile : classes.tableCellHeader} align="center">
                                    &nbsp;
                                </TableCell>
                                <TableCell className={isMobile ? classes.tableCellHeaderMobile : classes.tableCellHeader} align="center">
                                    Seller
                                </TableCell>
                                <TableCell className={isMobile ? classes.tableCellHeaderMobile : classes.tableCellHeader} align="center">
                                    <div style={{display: 'flex', justifyContent: 'center'}}>
                                        <CardConditions show>Condition</CardConditions>
                                    </div>
                                </TableCell>
                                <TableCell className={isMobile ? classes.tableCellHeaderMobile : classes.tableCellHeader} align="center">
                                    Price
                                </TableCell>
                                <TableCell className={isMobile ? classes.tableCellHeaderMobile : classes.tableCellHeader} align="center">
                                    {isMobile ? 'Qty' : 'Quantity'}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {listingsLoading && <BSCTableRowSkeleton amountOfRows={6} amountOfCells={4} />}
                            {!listingsLoading && (
                                <>
                                    {filteredListings?.length > 0 &&
                                        filteredListings?.map((listing, idx) => (
                                            <React.Fragment key={idx}>
                                                {listing.isFoldable === true && (
                                                    <FoldableComponent
                                                        listings={listings}
                                                        index={idx}
                                                        listing={listing}
                                                        type={type}
                                                        onClick={handleDefaultRowClick}
                                                        selected={selectedCardId === listing.cardId}
                                                        key={listing.productId}
                                                        onFoldableQtyChange={handleQuantityChange}
                                                        isVintage={isVintage}
                                                    ></FoldableComponent>
                                                )}
                                                {listing.isFoldable === false && (
                                                    <RowComponent
                                                        listing={listing}
                                                        type={type}
                                                        onClick={handleDefaultRowClick}
                                                        selected={selectedProductId === listing.productId}
                                                        key={listing.productId}
                                                        onQtyChange={handleQuantityChange}
                                                        isVintage={isVintage}
                                                    ></RowComponent>
                                                )}
                                            </React.Fragment>
                                        ))}
                                    {filteredListings?.length === 0 && (
                                        <TableRow>
                                            <TableCell colSpan={4}>
                                                <Box p={2} paddingTop={0}>
                                                    <Alert variant="filled" severity="info" color="error">
                                                        Be the First to Sell This Card!
                                                    </Alert>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </>
                            )}
                        </TableBody>
                    </Table>
                </div>
            </TableContainer>
        </>
    );
}

export default ProductListingTable;
