import {Grow} from '@material-ui/core';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {Alert, AlertTitle} from '@material-ui/lab';
import {useEffect, useRef, useState} from 'react';
import {RootState} from './redux/reducers';
import {useSelector} from 'react-redux';
import useReduxToast from './features/hooks/redux/toast/useReduxToast';

const useStyles = makeStyles((theme: Theme) => ({
    toastItem: {
        position: 'fixed',
        right: theme.spacing(2),
        zIndex: 99999,
        boxShadow: '0px 0px 4px #B8C2CC',
        minWidth: 300,
        maxWidth: 300,
    },
}));

function ToastItem({stackPosition, severity, message, contextKey, toastId}) {
    const marketplaceState = useSelector((state: RootState) => state.marketplace);
    const ref = useRef<HTMLElement>();
    const classes = useStyles({stackPosition, elHeight: ref.current?.clientHeight});
    const {removeToast} = useReduxToast();
    const [open, setOpen] = useState(true);

    const [pwaOS, setPwaOS] = useState(null);
    useEffect(() => {
        if (marketplaceState) {
            if (marketplaceState.pwaSettings.pwaOS !== '') {
                setPwaOS(marketplaceState.pwaSettings.pwaOS);
            }
        }
    }, [marketplaceState]);

    useEffect(() => {
        const timeout = setTimeout(() => setOpen(false), 4000);
        return () => {
            clearTimeout(timeout);
        };
    }, []);

    return (
        <Grow
            in={open}
            style={{transitionDelay: '200ms'}}
            timeout={{enter: 200, appear: 200, exit: 300}}
            onExited={() => {
                removeToast(toastId);
            }}
            unmountOnExit
        >
            <Alert
                onClose={() => setOpen(false)}
                variant="filled"
                ref={ref}
                className={classes.toastItem}
                style={pwaOS !== null ? {top: 94 * stackPosition + 60, right: 16} : {top: 94 * stackPosition + 16, right: 16}}
                severity={severity}
            >
                <AlertTitle>{contextKey}</AlertTitle>
                {message}
            </Alert>
        </Grow>
    );
}

function Toasty() {
    const {toasts} = useReduxToast();
    return (
        <div>
            {toasts.map((toast, i) => (
                <ToastItem
                    key={toast.id}
                    toastId={toast.id}
                    stackPosition={i}
                    message={toast.message}
                    contextKey={toast.contextKey}
                    severity={toast.severity}
                />
            ))}
        </div>
    );
}

export default Toasty;
