import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import {makeStyles, Theme} from '@material-ui/core/styles';
import classNames from 'classnames';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useHistory, useRouteMatch} from 'react-router-dom';
import FooterClient from '../client/s3/footer/footerClient';
import BSCContainer from '../features/common/bscContainer/bscContainer';
import BSCTypography from '../features/common/bscTypography/bscTypography';
import {useQuery} from '../packages/core/src/hooks/useQuery';
import useScreenSnap from '../packages/core/src/hooks/useScreenSnap';
import {RootState} from '../redux/reducers';
import * as constants from '../shared/constants';
import LinkGroup from './linkGroup';
import SignInConfirmationModal from './SignInConfirmationModal';
import SocialLinkIconGroup from './socialLinkIconGroup';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(1),
        background: '#000000',
    },
    shop: {
        width: '40%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    sell: {
        width: '20%',
        [theme.breakpoints.down('sm')]: {
            width: '50%',
        },
    },
    linkWrapper: {
        padding: '5px',
    },
    link: {
        color: '#F5F8FA',
    },
    termsTitle: {
        color: theme.palette.common.white,
        fontSize: 10,
        fontStyle: 'unset',
        fontWeight: 'bold',
    },
    group: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    stickyPWAFooter: {
        position: 'sticky',
        bottom: 0,
        backgroundColor: '#fff',
        padding: '8px 8px 34px 8px',
        borderTop: '2px solid #d5d5d5',
        // boxShadow: '0px -4px 3px rgba(50, 50, 50, 0.25)',
    },
    navbarTab: {
        textAlign: 'center',
        flex: 1,
    },
    iconHeight: {
        height: 30,
    },
    navbarTextActive: {
        position: 'relative',
        top: -2,
        color: '#000',
        fontWeight: 700,
    },
    navbarTextInactive: {
        position: 'relative',
        top: -2,
        color: '#5f6368',
        fontWeight: 600,
    },
    badgeWrapper: {position: 'relative'},
    badge: {
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.subtitle2.fontSize,
        color: theme.palette.common.white,
        position: 'absolute',
        top: 0,
        right: 12,
        zIndex: 1,
        background: theme.palette.primary.main,
        padding: 5,
        width: 2,
        height: 2,
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        lineHeight: 1,
    },
}));

const BSCFooter = ({isMarketPlace}) => {
    const classes = useStyles();
    const {data} = useQuery(
        () => (isMarketPlace ? FooterClient.getMarketPlaceFooter() : FooterClient.getSellersLockerFooter()),
        [isMarketPlace]
    );
    const history = useHistory();
    const dispatch = useDispatch();
    const isProductListing = useRouteMatch('/product');
    const isSellers = useRouteMatch('/sellers');
    const {isDesktop, isBelowDesktop} = useScreenSnap();
    const mobileOS = ['Android', 'iOS', 'Windows'];
    const [pwaOS, setPwaOS] = useState(null);
    const [modalOpen, toggleModalOpen] = useState(false);
    const [activeNavbarTab, setActiveNavbarTab] = useState(0);
    const [targetNavbarTab, setTargetNavbarTab] = useState(0);
    const marketplaceState = useSelector((state: RootState) => state.marketplace);

    const navFromTap = targetTabIndex => {
        setTargetNavbarTab(targetTabIndex);
        switch (targetTabIndex) {
            case 0:
                history.push('/');
                break;
            case 1:
                if (localStorage.getItem('accessToken') !== null) {
                    history.push('/pwa/dashboard/buyer');
                } else {
                    history.push('/pwa/dashboard/account');
                }
                break;
            case 2:
                history.push('/pwa/dashboard/search');
                break;
            case 3:
                if (localStorage.getItem('accessToken') === null) {
                    toggleModalOpen(true);
                } else {
                    history.push('/pwa/dashboard/notifications');
                }
                break;
            case 4:
                if (localStorage.getItem('accessToken') === null) {
                    toggleModalOpen(true);
                } else {
                    history.push('/sellers');
                }
                break;
            default:
                break;
        }
        if (targetTabIndex !== 3 && targetTabIndex !== 4) {
            dispatch({
                type: constants.PWA_NAVBAR_ACTIVE_TAB,
                payload: {
                    pwaNavbarActive: targetTabIndex,
                },
            });
        }
        dispatch({
            type: constants.SELLER_STOREFRONT_RESET,
            payload: {},
        });
        dispatch({
            type: constants.PWA_SELLER_STOREFRONT_FEEDBACK_RATING,
            payload: {pwaSellerStorefrontFeedbackRating: 0},
        });
        dispatch({
            type: constants.PWA_SELLER_STOREFRONT_CARDS_SOLD,
            payload: {pwaSellerStorefrontCardsSold: 0},
        });

        dispatch({
            type: constants.PWA_BUYER_DASHBOARD_SELECTION,
            payload: {
                pwaBuyerDashboardSelection: 0,
            },
        });
    };
    const handleModalClose = e => {
        toggleModalOpen(false);
        if (e === true && targetNavbarTab === 3) {
            history.push('/pwa/dashboard/notifications');
        }
        if (e === true && targetNavbarTab === 4) {
            history.push('/sellers');
        }
    };

    const [buyerMessagesCount, setBuyerMessagesCount] = useState('');
    const [sellerMessagesCount, setSellerMessagesCount] = useState('');
    useEffect(() => {
        if (marketplaceState) {
            setPwaOS(marketplaceState.pwaSettings.pwaOS);
            setActiveNavbarTab(marketplaceState.pwaNavbarActive);
            if (marketplaceState.buyerMessagesCount !== '') {
                setBuyerMessagesCount(marketplaceState.buyerMessagesCount);
            } else {
                setBuyerMessagesCount('');
            }
            if (marketplaceState.sellerMessagesCount !== '') {
                setSellerMessagesCount(marketplaceState.sellerMessagesCount);
            } else {
                setSellerMessagesCount('');
            }
        }
    }, [marketplaceState]);

    const [isPWAProductListing, setIsPWAProductListing] = useState(false);
    useEffect(() => {
        if (isProductListing && pwaOS !== null) {
            setIsPWAProductListing(true);
        } else {
            setIsPWAProductListing(false);
        }
    }, [isProductListing, pwaOS]);

    return (
        <div style={isPWAProductListing ? {display: 'none'} : {display: 'block'}}>
            {isBelowDesktop && mobileOS.some(loneOS => pwaOS.includes(loneOS)) && (
                <div className={classes.stickyPWAFooter}>
                    <Grid container spacing={2} justifyContent="space-between">
                        <Grid item md={2} className={classes.navbarTab} style={{paddingLeft: 0}} onClick={() => navFromTap(0)}>
                            {activeNavbarTab === 0 && <span className="material-icons">home</span>}
                            {activeNavbarTab !== 0 && <span className="material-icons-outlined">home</span>}
                            <BSCTypography
                                size10
                                label="Home"
                                className={activeNavbarTab === 0 ? classes.navbarTextActive : classes.navbarTextInactive}
                            />
                        </Grid>
                        <Grid item md={2} className={classes.navbarTab} onClick={() => navFromTap(1)}>
                            {buyerMessagesCount !== '' && (
                                <div className={classes.badgeWrapper}>
                                    <span className={classes.badge} />
                                </div>
                            )}
                            {activeNavbarTab === 1 && <span className="material-icons">person</span>}
                            {activeNavbarTab !== 1 && <span className="material-icons-outlined">person</span>}
                            <BSCTypography size10 className={activeNavbarTab === 1 ? classes.navbarTextActive : classes.navbarTextInactive}>
                                Account
                            </BSCTypography>
                        </Grid>
                        <Grid item md={2} className={classes.navbarTab} onClick={() => navFromTap(2)}>
                            {activeNavbarTab === 2 && <span className="material-icons">search</span>}
                            {activeNavbarTab !== 2 && <span className="material-icons-outlined">search</span>}
                            <BSCTypography
                                size10
                                label="Search"
                                className={activeNavbarTab === 2 ? classes.navbarTextActive : classes.navbarTextInactive}
                            />
                        </Grid>
                        <Grid item md={2} className={classes.navbarTab} onClick={() => navFromTap(3)}>
                            {activeNavbarTab === 3 && <span className="material-icons">notifications</span>}
                            {activeNavbarTab !== 3 && <span className="material-icons-outlined">notifications</span>}
                            <BSCTypography
                                size10
                                label="Notifications"
                                className={activeNavbarTab === 3 ? classes.navbarTextActive : classes.navbarTextInactive}
                            />
                        </Grid>
                        <Grid item md={2} className={classes.navbarTab} style={{paddingRight: 0}} onClick={() => navFromTap(4)}>
                            {sellerMessagesCount !== '' && (
                                <div className={classes.badgeWrapper}>
                                    <span className={classes.badge} />
                                </div>
                            )}
                            {activeNavbarTab === 4 && <span className="material-icons">sell</span>}
                            {activeNavbarTab !== 4 && <span className="material-icons-outlined">sell</span>}
                            <BSCTypography
                                size10
                                label="Selling"
                                className={activeNavbarTab === 4 ? classes.navbarTextActive : classes.navbarTextInactive}
                            />
                        </Grid>
                    </Grid>
                </div>
            )}
            {(isDesktop || (isBelowDesktop && !mobileOS.some(loneOS => pwaOS.includes(loneOS)))) && (
                <div className={classes.root}>
                    <BSCContainer variant="page">
                        <Grid container spacing={4}>
                            {(data?.shop || data?.sell || data?.about || data?.help || data?.followus) && (
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        {data?.shop && (
                                            <Grid item className={classes.shop}>
                                                <LinkGroup title={data?.shop.title} links={data?.shop.links} col={6} />
                                            </Grid>
                                        )}
                                        {data?.sell && (
                                            <Grid item className={classes.sell}>
                                                <LinkGroup title={data?.sell.title} links={data?.sell.links} col={12} />
                                            </Grid>
                                        )}
                                        {data?.about && (
                                            <Grid item className={classes.sell}>
                                                <LinkGroup title={data?.about.title} links={data?.about.links} col={12} />
                                            </Grid>
                                        )}
                                        <Grid item className={classNames(classes.sell, classes.group)}>
                                            <Grid container spacing={2}>
                                                {data?.help && (
                                                    <Grid item xs={6} md={12}>
                                                        <LinkGroup title={data?.help.title} links={data?.help.links} col={12} />
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Grid>
                                        {data?.followus && (
                                            <Grid item className={classes.sell}>
                                                <SocialLinkIconGroup title={data?.followus.title} links={data?.followus.links} />
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <Grid
                                    container
                                    justifyContent="space-between"
                                    alignItems="center"
                                    direction={isDesktop ? 'row' : 'column-reverse'}
                                >
                                    {data?.terms && (
                                        <Grid item>
                                            <BSCTypography label="©2032 BuySportsCards.com Inc." className={classes.termsTitle} />
                                        </Grid>
                                    )}
                                    {data?.terms && (
                                        <Grid item style={{minWidth: 300}}>
                                            <Grid container spacing={2} direction="row">
                                                {data?.terms.links &&
                                                    data?.terms.links.map(link => {
                                                        return (
                                                            <Grid
                                                                item
                                                                xs={!isSellers ? (isDesktop ? 6 : 6) : isDesktop ? 4 : 4}
                                                                key={link.url}
                                                            >
                                                                <MenuItem
                                                                    className={classes.linkWrapper}
                                                                    component={Link}
                                                                    to={link.url}
                                                                    onClick={() => {
                                                                        window.scrollTo(0, 0);
                                                                    }}
                                                                    style={{justifyContent: 'center'}}
                                                                >
                                                                    <BSCTypography
                                                                        label={link.title}
                                                                        variant="subtitle1"
                                                                        className={classes.termsTitle}
                                                                    />
                                                                </MenuItem>
                                                            </Grid>
                                                        );
                                                    })}
                                            </Grid>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </BSCContainer>
                </div>
            )}
            <SignInConfirmationModal open={modalOpen} onClose={e => handleModalClose(e)} targetTab={targetNavbarTab} />
        </div>
    );
};

export default BSCFooter;
