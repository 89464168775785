/* eslint-disable @typescript-eslint/no-unused-vars */
import {Grid} from '@material-ui/core';
import {Theme} from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import {useHistory} from 'react-router';
import useDateTimeNormalizer from '../../../../../client/common/normalizer/dateTimeNormalizer';
import Normalizer from '../../../../../client/common/normalizer/normalizer';
import {Order} from '../../../../../client/openapitools/seller';
import BSCButton from '../../../../../features/common/bscButton/bscButton';
import BSCCardBox from '../../../../../features/common/bscCardBox/bscCardBox';
import BSCCurrency from '../../../../../features/common/bscCurrency/bscCurrency';
import BSCLoading from '../../../../../features/common/bscLoading/bscLoading';
import BSCTypography from '../../../../../features/common/bscTypography/bscTypography';
import BSCTableRowSkeleton from '../../../../../features/common/skeletons/BSCTableRowSkeleton';
import useScreenSnap from '../../../../../packages/core/src/hooks/useScreenSnap';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        boxShadow: 'none',
        padding: '0',
        marginBottom: theme.spacing(2),
        overflowX: 'auto',
    },
    table: {
        minWidth: 505,
    },
    tr: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
    th: {
        color: '#41474C',
        padding: theme.spacing(1),
        background: '#DAE0E6',
        borderBottom: 'none',
        '&:first-child': {
            padding: '8px 40px',
        },
    },
    td: {
        borderBottom: '2px solid #e5e5e5',
        fontSize: '12px',
        padding: theme.spacing(1),
        '&:first-child': {
            padding: '8px 40px',
        },
    },
    checkboxWrapper: {
        background: theme.palette.common.white,
        border: '0.520833px solid #DAE0E6',
        boxShadow: '0px 0px 4px #B8C2CC',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '25px',
        height: '25px',
    },
    checkbox: {
        background: '#EF2424',
        boxShadow: 'inset 0px 0px 2.08333px rgba(0, 0, 0, 0.25), inset 0px 2.08333px 2.08333px rgba(255, 255, 255, 0.24)',
        width: '17px',
        height: '17px',
    },
    checked: {
        background: '#e5e5e5',
    },
    manageButton: {
        fontSize: theme.typography.subtitle2.fontSize,
    },
    unshippedBG: {
        backgroundColor: '#ffa4a4',
    },
}));

interface OrdersTableProps {
    sizePerPage: number;
    page: number;
    exportOrderList?: string[];
    onExportOrderListChange?: (prevState) => string[];
    ordersHistory: Array<Order>;
    loading: boolean;
}

const OrdersTable = ({ordersHistory, loading}: OrdersTableProps) => {
    const classes = useStyles();
    const history = useHistory();
    const {isMobile} = useScreenSnap();
    const dateNormalizer = useDateTimeNormalizer();

    return (
        <>
            <BSCCardBox className={classes.root}>
                {!isMobile && (
                    <TableContainer>
                        <Table stickyHeader aria-label="sticky table" className={classes.table}>
                            <TableHead>
                                {(ordersHistory?.length > 0 || loading) && (
                                    <TableRow>
                                        <TableCell key="orderNo" className={classes.th} style={{width: '22%'}}>
                                            <BSCTypography label="Order #" semibold />
                                        </TableCell>
                                        <TableCell key="buyerName" className={classes.th} style={{width: '18%'}}>
                                            <BSCTypography label="Name" semibold />
                                        </TableCell>
                                        <TableCell key="date" className={classes.th} style={{width: '20%'}}>
                                            <BSCTypography label="Order Date" semibold />
                                        </TableCell>
                                        <TableCell key="status" className={classes.th} style={{width: '10%'}}>
                                            <BSCTypography label="Status" semibold />
                                        </TableCell>
                                        <TableCell key="total" className={classes.th} style={{width: '15%'}}>
                                            <BSCTypography label="Total" semibold style={{textAlign: 'right'}} />
                                        </TableCell>
                                        <TableCell
                                            key="action"
                                            className={classes.th}
                                            style={{textAlign: 'center', width: '15%', paddingLeft: 40}}
                                        >
                                            <BSCTypography label="Action" semibold />
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableHead>
                            <TableBody>
                                {loading && <BSCTableRowSkeleton amountOfRows={4} amountOfCells={6} />}
                                {!loading &&
                                    ordersHistory?.map((pagedOrder, index) => {
                                        const rts = Normalizer.getOrderStatusDisplay(pagedOrder.orderStatus) === 'Ready To Ship';
                                        return (
                                            <TableRow
                                                hover={!rts}
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={index}
                                                className={(classes.tr, rts ? classes.unshippedBG : '')}
                                            >
                                                <TableCell key="orderNo" className={classes.td} style={{width: '22%'}}>
                                                    {pagedOrder.orderNo}
                                                </TableCell>
                                                <TableCell key="buyerName" className={classes.td} style={{width: '18%'}}>
                                                    {`${pagedOrder?.buyer?.firstName} ${pagedOrder?.buyer?.lastName}`}
                                                </TableCell>
                                                <TableCell key="date" className={classes.td} style={{width: '20%'}}>
                                                    {dateNormalizer.getDisplayDateTime(pagedOrder.orderDate)}
                                                </TableCell>
                                                <TableCell key="status" className={classes.td} style={{width: '10%'}}>
                                                    {Normalizer.getOrderStatusDisplay(pagedOrder.orderStatus)}
                                                </TableCell>
                                                <TableCell key="total" className={classes.td} style={{width: '15%'}}>
                                                    <BSCCurrency amount={pagedOrder.orderSubtotal} style={{textAlign: 'right'}} />
                                                </TableCell>
                                                <TableCell
                                                    key="action"
                                                    className={classes.td}
                                                    style={{textAlign: 'center', width: '15%', paddingLeft: 40}}
                                                >
                                                    <BSCButton
                                                        color="secondary"
                                                        onClick={() =>
                                                            history.push({
                                                                pathname: '/sellers/orders/order-detail',
                                                                state: {
                                                                    results: ordersHistory,
                                                                    selectedIndex: index,
                                                                },
                                                            })
                                                        }
                                                        className={classes.manageButton}
                                                    >
                                                        Manage
                                                    </BSCButton>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
                {isMobile && loading && <BSCLoading loading={loading} />}
                {isMobile &&
                    !loading &&
                    ordersHistory?.map((pagedOrder, index) => {
                        const rts = Normalizer.getOrderStatusDisplay(pagedOrder.orderStatus) === 'Ready To Ship';
                        return (
                            <Grid
                                container
                                xs={12}
                                key={index}
                                style={{padding: 4, borderBottom: '2px solid #e5e5e5'}}
                                className={(classes.tr, rts ? classes.unshippedBG : '')}
                            >
                                <Grid item xs={6} style={{paddingLeft: 8}}>
                                    <BSCTypography size10 label={pagedOrder.orderNo} />
                                    <BSCTypography size10 label={`${pagedOrder?.buyer?.firstName} ${pagedOrder?.buyer?.lastName}`} />
                                    <BSCTypography semibold size10 label={dateNormalizer.getDisplayDateTime(pagedOrder.orderDate)} />
                                </Grid>
                                <Grid item xs={3}>
                                    <BSCTypography size10 label={Normalizer.getOrderStatusDisplay(pagedOrder.orderStatus)} />
                                    <div style={{height: 15}}>&nbsp;</div>
                                    <BSCCurrency size10 semibold amount={pagedOrder.orderSubtotal} />
                                </Grid>
                                <Grid item xs={3} style={{margin: 'auto'}}>
                                    <BSCButton
                                        style={{fontSize: 10}}
                                        color="secondary"
                                        onClick={() =>
                                            history.push({
                                                pathname: '/sellers/orders/order-detail',
                                                state: {
                                                    results: ordersHistory,
                                                    selectedIndex: index,
                                                },
                                            })
                                        }
                                        className={classes.manageButton}
                                    >
                                        Manage
                                    </BSCButton>
                                </Grid>
                            </Grid>
                        );
                    })}
            </BSCCardBox>
        </>
    );
};

export default OrdersTable;
