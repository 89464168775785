import {makeStyles, Theme} from '@material-ui/core/styles';
import React, {useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Redirect, Route, Switch, useParams} from 'react-router';
import {useHistory} from 'react-router-dom';
import BSCContainer from '../../../../features/common/bscContainer/bscContainer';
import BSCTab from '../../../../features/common/bscTab/bscTab';
import BSCTabs from '../../../../features/common/bscTabs/bscTabs';
import BSCTypography from '../../../../features/common/bscTypography/bscTypography';
import {RootState} from '../../../../redux/reducers';
import Faq from './components/faq';
import Account from './tabs/account';
import Messages from './tabs/messages';
import Offers from './tabs/offers';
import OrderHistory from './tabs/orderHistory';

const tabEnums = {
    profile: {path: 'profile', key: 0},
    messages: {path: 'messages', key: 1},
    orderhistory: {path: 'orderHistory', key: 2},
    offers: {path: 'offers', key: 3},
    faq: {path: 'faq', key: 4},
    sellnow: {path: 'sellnow', key: 5},
};

const useStyles = makeStyles((theme: Theme) => ({
    cap: {
        width: '100%',
        overflowX: 'auto',
    },
    tabWrapper: {
        minWidth: 1200,
        [theme.breakpoints.down('xs')]: {
            minWidth: 550,
        },
        marginBottom: theme.spacing(2),
    },
    tabItem: {
        fontSize: theme.typography.body2.fontSize,
    },
    pwaContentWrapper: {
        height: 'calc(100vh - 225px)',
        overflowY: 'scroll',
    },
}));

function Dashboard() {
    const classes = useStyles();
    const history = useHistory();
    const marketplaceState = useSelector((state: RootState) => state.marketplace);
    const {selectedTab}: {selectedTab: string} = useParams();
    const activeTab = tabEnums?.[selectedTab.toLowerCase()]?.key ?? null;
    const [pwaOS, setPwaOS] = useState(null);

    useEffect(() => {
        if (!activeTab) {
            history.push('/dashboard/profile');
        }
    }, [activeTab, history]);

    useEffect(() => {
        if (marketplaceState) {
            if (marketplaceState.pwaSettings.pwaOS !== '') {
                setPwaOS(marketplaceState.pwaSettings.pwaOS);
            }
        }
    }, [marketplaceState]);

    const handleTabChange = useCallback(
        (newTabKey: number) => {
            let newActiveTab = tabEnums.profile.path;
            for (const tabKey in tabEnums) {
                const item = tabEnums[tabKey];
                newActiveTab = item.key === newTabKey ? item.path : newActiveTab;
            }

            history.push(`/dashboard/${newActiveTab}`);
        },
        [history]
    );

    return (
        <BSCContainer variant="page" className={pwaOS !== null ? classes.pwaContentWrapper : null}>
            {pwaOS === null && (
                <div className={classes.cap}>
                    <div className={classes.tabWrapper}>
                        <BSCTabs activeTab={activeTab} onTabChange={handleTabChange} variant="fullWidth">
                            <BSCTab label={<BSCTypography size16 label="Profile" />} value={tabEnums.profile.key} />
                            <BSCTab label={<BSCTypography size16 label="Messages" />} value={tabEnums.messages.key} />
                            <BSCTab label={<BSCTypography size16 label="Order History" />} value={tabEnums.orderhistory.key} />
                            <BSCTab label={<BSCTypography size16 label="Offers" />} value={tabEnums.offers.key} />
                            <BSCTab label={<BSCTypography size16 label="FAQ" />} value={tabEnums.faq.key} />
                        </BSCTabs>
                    </div>
                </div>
            )}
            <Switch>
                <Redirect push from="/dashboard/" to="/dashboard/profile/" exact />
                <Route path={'/dashboard/messages'}>
                    <Messages />
                </Route>
                <Route exact path={'/dashboard/profile'}>
                    <Account />
                </Route>
                <Route exact path={'/dashboard/orderHistory'}>
                    <OrderHistory />
                </Route>
                <Route exact path={'/dashboard/offers'}>
                    <Offers />
                </Route>
                <Route exact path={'/dashboard/faq'}>
                    <Faq />
                </Route>
                {/* <Route exact path={'/dashboard/sellNow'}>
                    <SellNow />
                </Route> */}
            </Switch>
        </BSCContainer>
    );
}

export default Dashboard;
