/* eslint-disable */
import {AccountInfo, SilentRequest} from '@azure/msal-browser';
import {useMsal} from '@azure/msal-react';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import {makeStyles, Theme, useTheme} from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AUTH_REQUESTS} from '../../../../authConfig';
import {useAuthState} from '../../../../AuthContext';
import {
    RecentSearchControllerApi,
    SavedSearchControllerApi,
    SavedSellerSearchControllerApi,
} from '../../../../client/openapitools/common/api';
import useReduxToast from '../../../../features/hooks/redux/toast/useReduxToast';
import useReduxUser from '../../../../features/hooks/redux/user/useReduxUser';
import {RootState} from '../../../../redux/reducers';
import * as constants from '../../../../shared/constants';
import SearchDashboardList from '../search/SearchDashboard/SearchDashboardList';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        // width: 'calc(100vw + 8px)',
        height: 'calc(100% - 100px)',
    },
    capitalizeTabs: {
        textTransform: 'none !important',
    },
    noPad: {
        padding: 'none !important',
    },
    boxPadding: {
        padding: '8px 0 8px 8px',
    },
}));
interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: undefined;
    value: undefined;
}

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;
    const classes = useStyles();
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={1} className={classes.boxPadding}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: undefined) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

function PWASearchDashboard() {
    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = useState(0);
    const dispatch = useDispatch();
    const {apiAuthConfig} = useAuthState();
    const {getCartSessionId} = useReduxUser();
    const {instance} = useMsal();
    const {addToast} = useReduxToast();
    const marketplaceState = useSelector((state: RootState) => state.marketplace);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index: number) => {
        setValue(index);
    };

    const [recentSearchResults, setRecentSearchResults] = useState([]);
    const [savedSearchResults, setSavedSearchResults] = useState([]);
    const [savedSellerResults, setSavedSellerResults] = useState([]);

    const fetchData = async () => {
        const account = instance.getActiveAccount();
        if (account !== null) {
            const tokenRequest: SilentRequest = {
                account: account as AccountInfo | undefined,
                scopes: AUTH_REQUESTS.LOGIN.scopes,
            };
            instance
                .acquireTokenSilent(tokenRequest)
                .then(async response => {
                    if (response.accessToken) {
                        const apiConfig = {...apiAuthConfig, accessToken: response.accessToken};
                        try {
                            const recentSearchApi = new RecentSearchControllerApi(apiConfig);
                            const recentSearchResults = await recentSearchApi.getAllRecentSearchByUserId();
                            if (recentSearchResults.status === 200) {
                                setRecentSearchResults(recentSearchResults.data);
                            }
                            const savedSearchApi = new SavedSearchControllerApi(apiConfig);
                            const savedSearchResults = await savedSearchApi.getAllSavedSearchByUserId();
                            if (savedSearchResults.status === 200) {
                                setSavedSearchResults(savedSearchResults.data);
                            }

                            const savedSellerApi = new SavedSellerSearchControllerApi(apiConfig);
                            const savedSellerResults = await savedSellerApi.getAllSavedSellerSearchByUserId();
                            if (savedSearchResults.status === 200) {
                                setSavedSellerResults(savedSellerResults.data);
                            }
                        } catch (error) {}
                    }
                })
                .catch(error => {
                    console.error('Error fetching user', error);
                });
        } else {
            const sessionId = getCartSessionId();
            const recentSearchApi = new RecentSearchControllerApi();
            const recentSearchResults = await recentSearchApi.getAllRecentSearchBySessionId(sessionId);
            if (recentSearchResults.status === 200) {
                setRecentSearchResults(recentSearchResults.data);
            }
        }
    };

    const handleClearAllRecent = () => {
        const account = instance.getActiveAccount();
        if (account !== null) {
            const tokenRequest: SilentRequest = {
                account: account as AccountInfo | undefined,
                scopes: AUTH_REQUESTS.LOGIN.scopes,
            };
            instance
                .acquireTokenSilent(tokenRequest)
                .then(async response => {
                    if (response.accessToken) {
                        const apiConfig = {...apiAuthConfig, accessToken: response.accessToken};
                        try {
                            const recentSearchApi = new RecentSearchControllerApi(apiConfig);
                            recentSearchApi.deleteAllByUserId();
                            setRecentSearchResults([]);
                        } catch (error) {
                            console.log('error', error);
                        }
                    }
                })
                .catch(error => {
                    console.error('Error fetching user', error);
                });
        } else {
            try {
                const sessionId = getCartSessionId();
                const recentSearchApi = new RecentSearchControllerApi();
                recentSearchApi.deleteAllBySessionId(sessionId);
                setRecentSearchResults([]);
            } catch (error) {
                console.log('error', error);
            }
        }
    };

    const handleTrashListing = async (id: string) => {
        const account = instance.getActiveAccount();
        if (account !== null) {
            const tokenRequest: SilentRequest = {
                account: account as AccountInfo | undefined,
                scopes: AUTH_REQUESTS.LOGIN.scopes,
            };
            instance
                .acquireTokenSilent(tokenRequest)
                .then(async response => {
                    if (response.accessToken) {
                        const apiConfig = {...apiAuthConfig, accessToken: response.accessToken};
                        try {
                            const savedSearchApi = new SavedSearchControllerApi(apiConfig);
                            const savedSearchResults = await savedSearchApi.deleteSavedSearch(id);
                            addToast({
                                severity: 'success',
                                message: 'Saved Search removed',
                            });
                            fetchData();
                        } catch (error) {
                            console.log('error', error);
                        }
                    }
                })
                .catch(error => {
                    console.error('Error fetching user', error);
                });
        }
    };

    const handleTrashSeller = async (id: string) => {
        const account = instance.getActiveAccount();
        if (account !== null) {
            const tokenRequest: SilentRequest = {
                account: account as AccountInfo | undefined,
                scopes: AUTH_REQUESTS.LOGIN.scopes,
            };
            instance
                .acquireTokenSilent(tokenRequest)
                .then(async response => {
                    if (response.accessToken) {
                        const apiConfig = {...apiAuthConfig, accessToken: response.accessToken};
                        try {
                            const savedSellerApi = new SavedSellerSearchControllerApi(apiConfig);
                            const savedSellerResults = await savedSellerApi.deleteSavedSellerSearch(id);
                            addToast({
                                severity: 'success',
                                message: 'Saved Seller removed',
                            });
                            fetchData();
                        } catch (error) {
                            console.log('error', error);
                        }
                    }
                })
                .catch(error => {
                    console.error('Error fetching user', error);
                });
        }
    };

    useEffect(() => {
        if (marketplaceState) {
            if (marketplaceState.pwaBuyerDashboardSelection === 2) {
                handleChangeIndex(1);
            }
        }
    }, [marketplaceState]);

    useEffect(() => {
        dispatch({
            type: constants.PWA_NAVBAR_ACTIVE_TAB,
            payload: {
                pwaNavbarActive: 2,
            },
        });
        dispatch({
            type: constants.SELLER_STOREFRONT_RESET,
            payload: {},
        });
        fetchData();
    }, []);

    return (
        <div className={classes.root}>
            <AppBar position="static" color="default">
                <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary">
                    <Tab
                        label="Recent"
                        {...a11yProps(0)}
                        className={classes.capitalizeTabs}
                        style={value === 0 ? {fontWeight: 'bolder'} : {fontWeight: 400}}
                    />
                    <Tab
                        label="Saved"
                        {...a11yProps(1)}
                        className={classes.capitalizeTabs}
                        style={value === 1 ? {fontWeight: 'bolder'} : {fontWeight: 400}}
                    />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0} dir={theme.direction}>
                <SearchDashboardList listings={recentSearchResults} clearAllRecent={handleClearAllRecent} view={0} />
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
                <SearchDashboardList
                    listings={savedSearchResults}
                    sellers={savedSellerResults}
                    handleTrashListing={handleTrashListing}
                    handleTrashSeller={handleTrashSeller}
                    view={1}
                />
            </TabPanel>
        </div>
    );
}

export default PWASearchDashboard;
