import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {WithAuthHOCProps} from '../../../@types/withAuth';
import BSCPrivacy from '../../../bscFooter/bscPrivacy';
import BSCTerms from '../../../bscFooter/bscTerms';
import withAuth from '../../../features/HOCs/withAuth';
import NotFound from '../../../features/navigation/NotFound';
import BSCPromo from '../pages/bscPromo';
import CartSummary from '../pages/cart/cartSummary';
import CheckOutCart from '../pages/cart/checkoutCart';
import EditCart from '../pages/cart/editCart';
import ThankYou from '../pages/cart/thankYou';
import Faq from '../pages/dashboard/components/faq';
import Dashboard from '../pages/dashboard/dashboard';
import ContactUs from '../pages/help/ContactUs';
import AboutUs from '../pages/home/aboutUs';
import Home from '../pages/home/home';
import ProductPage from '../pages/productPage/productPage';
import PWABSCAccount from '../pages/pwa/pwaBSCAccount';
import PWABuyerDashboard from '../pages/pwa/pwaBuyerDashboard';
import PWANotifications from '../pages/pwa/pwaNotifications';
import PWASearchDashboard from '../pages/pwa/pwaSearchDashboard';
import PWASellerDashboard from '../pages/pwa/pwaSellerDashboard';
import SearchPage from '../pages/search/SearchPage';
import Seller from '../pages/seller';

const RouteWithAuth = withAuth(Route);

const routes = [
    {
        component: () => <Home />,
        exact: true,
        path: '/',
        protectedRoute: false,
    },
    {
        component: () => <SearchPage />,
        exact: true,
        path: '/search',
        protectedRoute: false,
    },
    {
        component: () => <AboutUs />,
        exact: true,
        path: '/aboutUs',
        protectedRoute: false,
    },
    {
        component: () => <ProductPage />,
        path: '/product/:productId',
        protectedRoute: false,
    },
    {
        component: () => <CartSummary />,
        path: '/cart/summary',
        protectedRoute: false,
    },
    {
        component: () => <CheckOutCart />,
        path: '/cart/checkout',
        protectedRoute: false,
        exact: true,
    },
    {
        component: () => <EditCart />,
        path: '/cart/edit',
        protectedRoute: false,
        exact: true,
    },
    {
        component: () => <ThankYou />,
        path: '/cart/thank-you',
        protectedRoute: false,
        exact: true,
    },
    {
        component: () => <Dashboard />,
        path: '/dashboard/:selectedTab',
        protectedRoute: true,
    },
    {
        component: () => <PWABSCAccount />,
        path: '/pwa/dashboard/account',
        protectedRoute: false,
    },
    {
        component: () => <PWABuyerDashboard />,
        path: '/pwa/dashboard/buyer',
        protectedRoute: true,
    },
    {
        component: () => <PWASearchDashboard />,
        path: '/pwa/dashboard/search',
        protectedRoute: false,
    },
    {
        component: () => <PWANotifications />,
        path: '/pwa/dashboard/notifications',
        protectedRoute: true,
    },
    {
        component: () => <PWASellerDashboard />,
        path: '/pwa/dashboard/seller',
        protectedRoute: true,
    },
    {
        component: () => <ContactUs />,
        path: '/support',
        protectedRoute: false,
    },
    {
        component: () => <Faq />,
        path: '/faq',
        protectedRoute: false,
    },
    {
        component: () => <BSCTerms />,
        path: '/terms',
        protectedRoute: false,
    },
    {
        component: () => <BSCPromo />,
        path: '/promo',
        protectedRoute: false,
    },
    {
        component: () => <BSCPrivacy />,
        path: '/privacy',
        protectedRoute: false,
    },
    {
        component: () => <Seller />,
        path: '/Seller/:sellerId/:sellerName',
        protectedRoute: false,
    },
    {
        component: () => <NotFound />,
        protectedRoute: false,
    },
];

function MarketplaceRoutes() {
    return (
        <Switch>
            {routes.map((route: WithAuthHOCProps, index: number) => (
                <RouteWithAuth key={index} {...route} protectedRoute={route.protectedRoute} />
            ))}
        </Switch>
    );
}

export default MarketplaceRoutes;
