import {makeStyles, Theme} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
    bestSellerContainer: {
        // padding: '16px 16px 26px 16px !important' as '16px 26px',
        backgroundColor: 'white',
        // marginBottom: '24px !important' as '24px',
        // marginTop: '24px !important' as '24px',
    },
    featureContainer: {
        padding: '16px 26px !important' as '16px 36px',
        backgroundColor: 'white',
        marginBottom: '24px !important' as '24px',
        marginTop: '24px !important' as '24px',
    },
    cardRoot: {
        position: 'relative',
        cursor: 'pointer',
    },
    cardImage: {
        maxWidth: 129,
        position: 'relative',
        maxHeight: 180,
        cursor: 'pointer',
        [theme.breakpoints.up('lg')]: {
            minWidth: 129,
        },
        [theme.breakpoints.up('sm')]: {
            minWidth: 120,
        },
        [theme.breakpoints.down('xs')]: {
            minWidth: 81,
        },
    },
    filterWrap: {
        marginBottom: 4,
    },
    setRoot: {
        position: 'relative',
        cursor: 'pointer',
        width: '100%',
        boxSizing: 'border-box',
        padding: '16px',
        // minWidth: 240,
        // [theme.breakpoints.up('lg')]: {
        //     minWidth: 240,
        // },
        // [theme.breakpoints.down('md')]: {
        //     minWidth: 218,
        // },
        // [theme.breakpoints.down('xs')]: {
        //     minWidth: 158,
        // },
        // [theme.breakpoints.between('sm', 'sm')]: {
        //     minWidth: 205,
        // },
        backgroundColor: '#DAE0E6',
    },
    sidePadding: {
        marginLeft: '15px',
        marginRight: '15px',
    },
    sliderNoWrap: {
        flexWrap: 'nowrap !important' as 'nowrap',
        // touchAction: 'pan-x',
    },
    setBox: {
        backgroundColor: 'white',
        padding: theme.spacing(4, 0),
    },
    setTitleWrap: {
        marginBottom: theme.spacing(2),
        marginLeft: theme.spacing(2),
    },
    // badges: {
    //     display: 'flex',
    //     flexDirection: 'column',
    //     justifyContent: 'center',
    //     alignItems: 'center',
    //     maxWidth: 42,
    //     maxHeight: 42,
    //     width: '17%',
    //     left: 0,
    //     top: 0,
    //     background: 'black',
    //     borderRadius: '4px 0px',
    //     position: 'absolute',
    //     zIndex: 2,
    // },
    // badge: {
    //     fontSize: 30,
    //     color: theme.palette.common.white,
    // },
    setName: {
        fontSize: '12px',
        //  marginBottom: '12pt',
    },
    sectionHeader: {
        fontSize: '28px',
        fontWeight: 400,
    },
    title: {
        fontWeight: 600,
    },
    image: {
        maxWidth: 120,
        margin: theme.spacing(0, 'auto'),
        display: 'block',
    },
    sportImage: {
        position: 'relative',
        cursor: 'pointer',
        // maxWidth: 200,
        // maxHeight: 200,
        // [theme.breakpoints.up('lg')]: {
        //     minWidth: 200,
        // },
        // [theme.breakpoints.up('sm')]: {
        //     minWidth: 246,
        // },
        // [theme.breakpoints.down('xs')]: {
        //     minWidth: 81,
        // },
    },
    setImage: {
        //  minWidth: 120,
        minHeight: 40,
        margin: theme.spacing(0, 'auto'),
        filter: 'drop-shadow(0 1px 1px grey)',
    },
    cardWrapper: {},
    setWrapper: {
        // [theme.breakpoints.up('lg')]: {
        //     minWidth: 180,
        // },
        // [theme.breakpoints.down('md')]: {
        //     minWidth: 190,
        // },
        // [theme.breakpoints.down('xs')]: {
        //     minWidth: 90,
        // },
        // [theme.breakpoints.between('sm', 'sm')]: {
        //     minWidth: 160,
        // },
        // minHeight: 100,
    },
    price: {
        color: theme.palette.primary.main,
        paddingLeft: 5,
    },
    priceLabel: {
        fontSize: '14px',
        fontWeight: 400,
    },
    wrapper: {},
    cardInfo: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
}));

export default useStyles;
