/* tslint:disable */
/* eslint-disable */
/**
 * Common API
 * All Common APIs for UI to access
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosInstance, AxiosPromise, AxiosRequestConfig } from 'axios';
import { Configuration } from './configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, createRequestFunction, serializeDataIfNeeded, setBearerAuthToObject, setSearchParams, toPathString } from './common';
// @ts-ignore
import { BASE_PATH, BaseAPI, RequestArgs, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ApiError
 */
export interface ApiError {
    /**
     * 
     * @type {string}
     * @memberof ApiError
     */
    'error'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiError
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiError
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiError
     */
    'timestamp'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiError
     */
    'path'?: string;
}
/**
 * 
 * @export
 * @interface Attachment
 */
export interface Attachment {
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    'objectKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    'url'?: string;
}
/**
 * 
 * @export
 * @interface AttachmentType
 */
export interface AttachmentType {
    /**
     * 
     * @type {string}
     * @memberof AttachmentType
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AttachmentType
     */
    'key'?: string;
}
/**
 * 
 * @export
 * @interface Card
 */
export interface Card {
    /**
     * The Set Name associated with this Card.
     * @type {string}
     * @memberof Card
     */
    'setName'?: string;
    /**
     * The name of the Player or Players on the Card.
     * @type {string}
     * @memberof Card
     */
    'players'?: string;
    /**
     * The Card Number associated with this Card.
     * @type {string}
     * @memberof Card
     */
    'cardNo'?: string;
    /**
     * The variant of the card.
     * @type {string}
     * @memberof Card
     */
    'variant': CardVariantEnum;
    /**
     * The Variant name associated with this Set variant for Card.
     * @type {string}
     * @memberof Card
     */
    'variantName'?: string;
    /**
     * The name of Sport associated with this Cards Player when it was printed.
     * @type {string}
     * @memberof Card
     */
    'sport'?: CardSportEnum;
    /**
     * The Year or Season when this Set or Card was release.
     * @type {string}
     * @memberof Card
     */
    'year'?: string;
    /**
     * The Team name(s) associated with this Player(s) on the Card when it was printed.
     * @type {string}
     * @memberof Card
     */
    'teamName'?: string;
    /**
     * The lowest price of the listed product and raw type associated with this Card.
     * @type {string}
     * @memberof Card
     */
    'rawLeastPrice'?: string;
    /**
     * The lowest price of the listed Graded product associated with this Card.
     * @type {string}
     * @memberof Card
     */
    'gradedLeastPrice'?: string;
    /**
     * The Player Attributes associated with this Player(s) on the Card. Example Rookie, Serial Numbered etc.
     * @type {string}
     * @memberof Card
     */
    'playerAttribute'?: string;
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    'playerAttributeAlias'?: string;
    /**
     * The Team name(s) associated with this Player(s) on the Card when it was printed.
     * @type {string}
     * @memberof Card
     */
    'playerAttributeDesc'?: string;
    /**
     * The Team name(s) associated with this Player(s) on the Card when it was printed.
     * @type {string}
     * @memberof Card
     */
    'stockImgFront'?: string;
    /**
     * The Team name(s) associated with this Player(s) on the Card when it was printed.
     * @type {string}
     * @memberof Card
     */
    'stockImgBack'?: string;
    /**
     * The Team name(s) associated with this Player(s) on the Card when it was printed.
     * @type {string}
     * @memberof Card
     */
    'productName'?: string;
    /**
     * The Team name(s) associated with this Player(s) on the Card when it was printed.
     * @type {string}
     * @memberof Card
     */
    'slug'?: string;
    /**
     * The Team name(s) associated with this Player(s) on the Card when it was printed.
     * @type {string}
     * @memberof Card
     */
    'totalListings'?: string;
    /**
     * The Team name(s) associated with this Player(s) on the Card when it was printed.
     * @type {number}
     * @memberof Card
     */
    'lowestPrice'?: number;
    /**
     * The Team name(s) associated with this Player(s) on the Card when it was printed.
     * @type {Array<Product>}
     * @memberof Card
     */
    'listings'?: Array<Product>;
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    '_id'?: string;
}

export const CardVariantEnum = {
    Base: 'Base',
    Insert: 'Insert',
    MailIn: 'Mail-In',
    Parallel: 'Parallel',
    Promo: 'Promo',
    Proof: 'Proof'
} as const;

export type CardVariantEnum = typeof CardVariantEnum[keyof typeof CardVariantEnum];
export const CardSportEnum = {
    Baseball: 'Baseball',
    Basketball: 'Basketball',
    Boxing: 'Boxing',
    Football: 'Football',
    Golf: 'Golf',
    Hockey: 'Hockey',
    MiscSports: 'Misc. Sports',
    Mma: 'MMA',
    MultiSport: 'Multi-Sport',
    NonSport: 'Non-Sport',
    Racing: 'Racing',
    Soccer: 'Soccer',
    Tennis: 'Tennis',
    Wrestling: 'Wrestling'
} as const;

export type CardSportEnum = typeof CardSportEnum[keyof typeof CardSportEnum];

/**
 * 
 * @export
 * @interface CarrierPackage
 */
export interface CarrierPackage {
    /**
     * 
     * @type {string}
     * @memberof CarrierPackage
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CarrierPackage
     */
    'key'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CarrierPackage
     */
    'active'?: boolean;
}
/**
 * 
 * @export
 * @interface Condition
 */
export interface Condition {
    /**
     * 
     * @type {Array<string>}
     * @memberof Condition
     */
    'raw'?: Array<string>;
    /**
     * 
     * @type {Graded}
     * @memberof Condition
     */
    'graded'?: Graded;
    /**
     * 
     * @type {boolean}
     * @memberof Condition
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface Conversation
 */
export interface Conversation {
    /**
     * 
     * @type {string}
     * @memberof Conversation
     */
    'orderNo'?: string;
    /**
     * 
     * @type {string}
     * @memberof Conversation
     */
    'orderId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Conversation
     */
    'read'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Conversation
     */
    'escalated'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Conversation
     */
    'fromMarketPlace'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Conversation
     */
    'fromUserId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Conversation
     */
    'toUserId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Conversation
     */
    'sellerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Conversation
     */
    'fromUserName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Conversation
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof Conversation
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Conversation
     */
    'updateType'?: string;
    /**
     * 
     * @type {string}
     * @memberof Conversation
     */
    'userRole'?: string;
    /**
     * 
     * @type {Array<Message>}
     * @memberof Conversation
     */
    'messages'?: Array<Message>;
    /**
     * 
     * @type {string}
     * @memberof Conversation
     */
    'convId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Conversation
     */
    'subjectKey'?: string;
}
/**
 * 
 * @export
 * @interface Graded
 */
export interface Graded {
    /**
     * 
     * @type {Array<string>}
     * @memberof Graded
     */
    'compundefined'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof Graded
     */
    'rangeStart'?: number;
    /**
     * 
     * @type {number}
     * @memberof Graded
     */
    'rangeEnd'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof Graded
     */
    'letterGrade'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof Graded
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface Message
 */
export interface Message {
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    'content'?: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    'fromUserId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    'toUserId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    'createdModule'?: string;
    /**
     * 
     * @type {Array<Attachment>}
     * @memberof Message
     */
    'attachments'?: Array<Attachment>;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    'userNameTo'?: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    'userNameFrom'?: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    'date'?: string;
}
/**
 * 
 * @export
 * @interface MessageReference
 */
export interface MessageReference {
    /**
     * 
     * @type {Array<Subject>}
     * @memberof MessageReference
     */
    'subjectTypes'?: Array<Subject>;
    /**
     * 
     * @type {Array<AttachmentType>}
     * @memberof MessageReference
     */
    'attachmentTypes'?: Array<AttachmentType>;
}
/**
 * 
 * @export
 * @interface OfferHistory
 */
export interface OfferHistory {
    /**
     * 
     * @type {string}
     * @memberof OfferHistory
     */
    'productId'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfferHistory
     */
    'sellerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfferHistory
     */
    'sellerName'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfferHistory
     */
    'buyerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfferHistory
     */
    'buyerName'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfferHistory
     */
    'cardId'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfferHistory
     */
    'sellerImgFront'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfferHistory
     */
    'sellerImgBack'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfferHistory
     */
    'offerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfferHistory
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfferHistory
     */
    'expiration'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfferHistory
     */
    'currency'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfferHistory
     */
    'offerPrice'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfferHistory
     */
    'offerShippingCost'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfferHistory
     */
    'offerStatus'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfferHistory
     */
    'orderId'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfferHistory
     */
    'price'?: string;
    /**
     * 
     * @type {number}
     * @memberof OfferHistory
     */
    'offerQuantity'?: number;
    /**
     * 
     * @type {string}
     * @memberof OfferHistory
     */
    'productType'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfferHistory
     */
    'condition'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfferHistory
     */
    'gradingCompundefined'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfferHistory
     */
    'grade'?: string;
    /**
     * 
     * @type {Product}
     * @memberof OfferHistory
     */
    'offerItem'?: Product;
    /**
     * 
     * @type {string}
     * @memberof OfferHistory
     */
    'userId'?: string;
}
/**
 * 
 * @export
 * @interface OfferResponse
 */
export interface OfferResponse {
    /**
     * 
     * @type {number}
     * @memberof OfferResponse
     */
    'totalResults'?: number;
    /**
     * 
     * @type {Array<OfferHistory>}
     * @memberof OfferResponse
     */
    'history'?: Array<OfferHistory>;
}
/**
 * 
 * @export
 * @interface Product
 */
export interface Product {
    /**
     * The Product Id is unique identifier of a listing added by seller.
     * @type {string}
     * @memberof Product
     */
    'productId'?: string;
    /**
     * The sellers image of the product.
     * @type {string}
     * @memberof Product
     */
    'sellerImgFront'?: string;
    /**
     * The back image of the sellers product.
     * @type {string}
     * @memberof Product
     */
    'sellerImgBack'?: string;
    /**
     * The product type or listing type of the product.
     * @type {string}
     * @memberof Product
     */
    'productType': ProductProductTypeEnum;
    /**
     * The Card Id associated with this product listing.
     * @type {string}
     * @memberof Product
     */
    'cardId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'price'?: string;
    /**
     * The condition is the value set by seller.
     * @type {string}
     * @memberof Product
     */
    'condition'?: ProductConditionEnum;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'availableQuantity'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    'offers'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    'active'?: boolean;
    /**
     * The grading companies that BSC allows to sell on the website.
     * @type {string}
     * @memberof Product
     */
    'gradingCompundefined'?: ProductGradingCompundefinedEnum;
    /**
     * The various grades given by the grading companies.
     * @type {string}
     * @memberof Product
     */
    'grade'?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'shippingCost'?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'currency'?: string;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'cartQty'?: number;
    /**
     * 
     * @type {Card}
     * @memberof Product
     */
    'card'?: Card;
    /**
     * 
     * @type {User}
     * @memberof Product
     */
    'seller'?: User;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'sellerId'?: string;
}

export const ProductProductTypeEnum = {
    Raw: 'raw',
    Graded: 'graded'
} as const;

export type ProductProductTypeEnum = typeof ProductProductTypeEnum[keyof typeof ProductProductTypeEnum];
export const ProductConditionEnum = {
    Mint: 'mint',
    NearMint: 'near_mint',
    VeryGood: 'very_good',
    Poor: 'poor',
    Fair: 'fair'
} as const;

export type ProductConditionEnum = typeof ProductConditionEnum[keyof typeof ProductConditionEnum];
export const ProductGradingCompundefinedEnum = {
    Bvg: 'bvg',
    Psa: 'psa',
    Sgc: 'sgc',
    Hga: 'hga',
    Bgs: 'bgs',
    Csg: 'csg'
} as const;

export type ProductGradingCompundefinedEnum = typeof ProductGradingCompundefinedEnum[keyof typeof ProductGradingCompundefinedEnum];

/**
 * 
 * @export
 * @interface ProductCriteria
 */
export interface ProductCriteria {
    /**
     * 
     * @type {string}
     * @memberof ProductCriteria
     */
    'type'?: string;
    /**
     * 
     * @type {Condition}
     * @memberof ProductCriteria
     */
    'condition'?: Condition;
    /**
     * 
     * @type {number}
     * @memberof ProductCriteria
     */
    'priceRangeStart'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductCriteria
     */
    'priceRangeEnd'?: number;
}
/**
 * 
 * @export
 * @interface ProductReference
 */
export interface ProductReference {
    /**
     * 
     * @type {string}
     * @memberof ProductReference
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductReference
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface RecentSearchDto
 */
export interface RecentSearchDto {
    /**
     * 
     * @type {string}
     * @memberof RecentSearchDto
     */
    'sessionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecentSearchDto
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecentSearchDto
     */
    'id'?: string;
    /**
     * 
     * @type {SavedSearchCriteria}
     * @memberof RecentSearchDto
     */
    'criteria'?: SavedSearchCriteria;
}
/**
 * 
 * @export
 * @interface ReferralChannel
 */
export interface ReferralChannel {
    /**
     * 
     * @type {string}
     * @memberof ReferralChannel
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReferralChannel
     */
    'key'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ReferralChannel
     */
    'active'?: boolean;
}
/**
 * 
 * @export
 * @interface SavedSearchCriteria
 */
export interface SavedSearchCriteria {
    /**
     * 
     * @type {string}
     * @memberof SavedSearchCriteria
     */
    'query'?: string;
    /**
     * 
     * @type {{ [key: string]: Set<string>; }}
     * @memberof SavedSearchCriteria
     */
    'filters'?: { [key: string]: Set<string>; };
    /**
     * 
     * @type {string}
     * @memberof SavedSearchCriteria
     */
    'sort'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SavedSearchCriteria
     */
    'inStock'?: boolean;
    /**
     * 
     * @type {ProductCriteria}
     * @memberof SavedSearchCriteria
     */
    'productCriteria'?: ProductCriteria;
    /**
     * 
     * @type {boolean}
     * @memberof SavedSearchCriteria
     */
    'myInventory'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SavedSearchCriteria
     */
    'sellerId'?: string;
    /**
     * 
     * @type {SellerFilter}
     * @memberof SavedSearchCriteria
     */
    'listingSearch'?: SellerFilter;
}
/**
 * 
 * @export
 * @interface SavedSearchDto
 */
export interface SavedSearchDto {
    /**
     * 
     * @type {string}
     * @memberof SavedSearchDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SavedSearchDto
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SavedSearchDto
     */
    'name'?: string;
    /**
     * 
     * @type {SavedSearchCriteria}
     * @memberof SavedSearchDto
     */
    'criteria'?: SavedSearchCriteria;
    /**
     * 
     * @type {boolean}
     * @memberof SavedSearchDto
     */
    'changedSinceLastRun'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SavedSearchDto
     */
    'seller'?: boolean;
}
/**
 * 
 * @export
 * @interface SavedSellerSearchDto
 */
export interface SavedSellerSearchDto {
    /**
     * 
     * @type {string}
     * @memberof SavedSellerSearchDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SavedSellerSearchDto
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SavedSellerSearchDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof SavedSellerSearchDto
     */
    'sellerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SavedSellerSearchDto
     */
    'sellerName'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SavedSellerSearchDto
     */
    'inventoryStatus'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SavedSellerSearchDto
     */
    'sellerIconUrl'?: string;
}
/**
 * 
 * @export
 * @interface SellerFilter
 */
export interface SellerFilter {
    /**
     * 
     * @type {string}
     * @memberof SellerFilter
     */
    'sellerKey'?: string;
}
/**
 * 
 * @export
 * @interface SellerProfile
 */
export interface SellerProfile {
    /**
     * 
     * @type {string}
     * @memberof SellerProfile
     */
    'sellerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SellerProfile
     */
    'sellerStoreName'?: string;
    /**
     * 
     * @type {string}
     * @memberof SellerProfile
     */
    'sellerRating'?: string;
    /**
     * 
     * @type {string}
     * @memberof SellerProfile
     */
    'sellerSales'?: string;
    /**
     * 
     * @type {string}
     * @memberof SellerProfile
     */
    'applicationStatus'?: string;
    /**
     * 
     * @type {string}
     * @memberof SellerProfile
     */
    'userId'?: string;
    /**
     * 
     * @type {number}
     * @memberof SellerProfile
     */
    'aggregateRating'?: number;
    /**
     * 
     * @type {number}
     * @memberof SellerProfile
     */
    'salesNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof SellerProfile
     */
    'shipmentCost'?: number;
    /**
     * 
     * @type {string}
     * @memberof SellerProfile
     */
    'sellerIconUrl'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SellerProfile
     */
    'offers'?: boolean;
}
/**
 * 
 * @export
 * @interface SportReference
 */
export interface SportReference {
    /**
     * 
     * @type {string}
     * @memberof SportReference
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof SportReference
     */
    'key'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SportReference
     */
    'active'?: boolean;
}
/**
 * 
 * @export
 * @interface Subject
 */
export interface Subject {
    /**
     * 
     * @type {string}
     * @memberof Subject
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Subject
     */
    'key'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Subject
     */
    'active'?: boolean;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'roleId'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'givenName'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'surname'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'extension_PhoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'username'?: string;
    /**
     * 
     * @type {SellerProfile}
     * @memberof User
     */
    'sellerProfile'?: SellerProfile;
}
/**
 * 
 * @export
 * @interface UserResponse
 */
export interface UserResponse {
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'version'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'action'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'extension_userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'extension_roleId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'userMessage'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'status'?: string;
}

/**
 * ConversationApi - axios parameter creator
 * @export
 */
export const ConversationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add conversation and Message
         * @param {Conversation} conversation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addConversation: async (conversation?: Conversation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/common/conversation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(conversation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add conversation and Message For Buyer
         * @param {Conversation} conversation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addConversationForBuyer: async (conversation?: Conversation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/common/conversation/buyer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(conversation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add conversation and Message For Seller
         * @param {Conversation} conversation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addConversationForSeller: async (conversation?: Conversation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/common/conversation/seller`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(conversation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete attachment for conversation. Send the name returned in POST response.
         * @param {string} conversationId 
         * @param {string} fileName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMessageAttachment: async (conversationId?: string, fileName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/common/conversation/{conversationId}/attachment/{fileName}`
                .replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)))
                .replace(`{${"fileName"}}`, encodeURIComponent(String(fileName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Buyer Messages by ConversationId
         * @param {string} convId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBuyerConversationById: async (convId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/common/conversation/buyer/{convId}`
                .replace(`{${"convId"}}`, encodeURIComponent(String(convId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Conversations history sorted by unread and date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversations: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/common/conversation/history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Conversations history for buyer sorted by unread and date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversationsForBuyer: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/common/conversation/buyer/history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Conversations history for seller sorted by unread and date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversationsForSeller: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/common/conversation/seller/history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Sellers Messages by ConversationId
         * @param {string} convId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSellerConversationById: async (convId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/common/conversation/seller/{convId}`
                .replace(`{${"convId"}}`, encodeURIComponent(String(convId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Conversation or add Messages by ConversationId
         * @param {Conversation} conversation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConversationById: async (conversation?: Conversation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/common/conversation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(conversation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Conversation or add Messages by ConversationId for buyer
         * @param {Conversation} conversation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConversationByIdForBuyer: async (conversation?: Conversation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/common/conversation/buyer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(conversation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Conversation or add Messages by ConversationId for seller
         * @param {Conversation} conversation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConversationByIdForSeller: async (conversation?: Conversation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/common/conversation/seller`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(conversation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload an attachment to the message
         * @param {string} conversationId 
         * @param {undefined} [attachment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadMessageAttachment: async (conversationId?: string, attachment?: undefined, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/common/conversation/{conversationId}/attachment`
                .replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (attachment !== undefined) { 
                localVarFormParams.append('attachment', attachment as undefined);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConversationApi - functional programming interface
 * @export
 */
export const ConversationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConversationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add conversation and Message
         * @param {Conversation} conversation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addConversation(conversation?: Conversation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Conversation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addConversation(conversation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add conversation and Message For Buyer
         * @param {Conversation} conversation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addConversationForBuyer(conversation?: Conversation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Conversation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addConversationForBuyer(conversation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add conversation and Message For Seller
         * @param {Conversation} conversation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addConversationForSeller(conversation?: Conversation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Conversation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addConversationForSeller(conversation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete attachment for conversation. Send the name returned in POST response.
         * @param {string} conversationId 
         * @param {string} fileName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMessageAttachment(conversationId?: string, fileName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Conversation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMessageAttachment(conversationId, fileName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Buyer Messages by ConversationId
         * @param {string} convId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBuyerConversationById(convId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Conversation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBuyerConversationById(convId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Conversations history sorted by unread and date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConversations(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Conversation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConversations(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Conversations history for buyer sorted by unread and date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConversationsForBuyer(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Conversation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConversationsForBuyer(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Conversations history for seller sorted by unread and date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConversationsForSeller(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Conversation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConversationsForSeller(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Sellers Messages by ConversationId
         * @param {string} convId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSellerConversationById(convId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Conversation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSellerConversationById(convId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Conversation or add Messages by ConversationId
         * @param {Conversation} conversation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateConversationById(conversation?: Conversation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Conversation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateConversationById(conversation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Conversation or add Messages by ConversationId for buyer
         * @param {Conversation} conversation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateConversationByIdForBuyer(conversation?: Conversation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Conversation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateConversationByIdForBuyer(conversation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Conversation or add Messages by ConversationId for seller
         * @param {Conversation} conversation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateConversationByIdForSeller(conversation?: Conversation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Conversation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateConversationByIdForSeller(conversation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload an attachment to the message
         * @param {string} conversationId 
         * @param {undefined} [attachment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadMessageAttachment(conversationId?: string, attachment?: undefined, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Attachment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadMessageAttachment(conversationId, attachment, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConversationApi - factory interface
 * @export
 */
export const ConversationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConversationApiFp(configuration)
    return {
        /**
         * 
         * @summary Add conversation and Message
         * @param {Conversation} conversation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addConversation(conversation?: Conversation, options?: undefined): AxiosPromise<Conversation> {
            return localVarFp.addConversation(conversation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add conversation and Message For Buyer
         * @param {Conversation} conversation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addConversationForBuyer(conversation?: Conversation, options?: undefined): AxiosPromise<Conversation> {
            return localVarFp.addConversationForBuyer(conversation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add conversation and Message For Seller
         * @param {Conversation} conversation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addConversationForSeller(conversation?: Conversation, options?: undefined): AxiosPromise<Conversation> {
            return localVarFp.addConversationForSeller(conversation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete attachment for conversation. Send the name returned in POST response.
         * @param {string} conversationId 
         * @param {string} fileName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMessageAttachment(conversationId?: string, fileName?: string, options?: undefined): AxiosPromise<Conversation> {
            return localVarFp.deleteMessageAttachment(conversationId, fileName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Buyer Messages by ConversationId
         * @param {string} convId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBuyerConversationById(convId?: string, options?: undefined): AxiosPromise<Conversation> {
            return localVarFp.getBuyerConversationById(convId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Conversations history sorted by unread and date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversations(options?: undefined): AxiosPromise<Conversation> {
            return localVarFp.getConversations(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Conversations history for buyer sorted by unread and date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversationsForBuyer(options?: undefined): AxiosPromise<Conversation> {
            return localVarFp.getConversationsForBuyer(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Conversations history for seller sorted by unread and date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversationsForSeller(options?: undefined): AxiosPromise<Conversation> {
            return localVarFp.getConversationsForSeller(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Sellers Messages by ConversationId
         * @param {string} convId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSellerConversationById(convId?: string, options?: undefined): AxiosPromise<Conversation> {
            return localVarFp.getSellerConversationById(convId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Conversation or add Messages by ConversationId
         * @param {Conversation} conversation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConversationById(conversation?: Conversation, options?: undefined): AxiosPromise<Conversation> {
            return localVarFp.updateConversationById(conversation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Conversation or add Messages by ConversationId for buyer
         * @param {Conversation} conversation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConversationByIdForBuyer(conversation?: Conversation, options?: undefined): AxiosPromise<Conversation> {
            return localVarFp.updateConversationByIdForBuyer(conversation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Conversation or add Messages by ConversationId for seller
         * @param {Conversation} conversation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConversationByIdForSeller(conversation?: Conversation, options?: undefined): AxiosPromise<Conversation> {
            return localVarFp.updateConversationByIdForSeller(conversation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload an attachment to the message
         * @param {string} conversationId 
         * @param {undefined} [attachment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadMessageAttachment(conversationId?: string, attachment?: undefined, options?: undefined): AxiosPromise<Attachment> {
            return localVarFp.uploadMessageAttachment(conversationId, attachment, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConversationApi - object-oriented interface
 * @export
 * @class ConversationApi
 * @extends {BaseAPI}
 */
export class ConversationApi extends BaseAPI {
    /**
     * 
     * @summary Add conversation and Message
     * @param {Conversation} conversation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationApi
     */
    public addConversation = (conversation?: Conversation, options?: AxiosRequestConfig) => {
        return ConversationApiFp(this.configuration).addConversation(conversation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add conversation and Message For Buyer
     * @param {Conversation} conversation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationApi
     */
    public addConversationForBuyer = (conversation?: Conversation, options?: AxiosRequestConfig) => {
        return ConversationApiFp(this.configuration).addConversationForBuyer(conversation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add conversation and Message For Seller
     * @param {Conversation} conversation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationApi
     */
    public addConversationForSeller = (conversation?: Conversation, options?: AxiosRequestConfig) => {
        return ConversationApiFp(this.configuration).addConversationForSeller(conversation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete attachment for conversation. Send the name returned in POST response.
     * @param {string} conversationId 
     * @param {string} fileName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationApi
     */
    public deleteMessageAttachment = (conversationId?: string, fileName?: string, options?: AxiosRequestConfig) => {
        return ConversationApiFp(this.configuration).deleteMessageAttachment(conversationId, fileName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Buyer Messages by ConversationId
     * @param {string} convId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationApi
     */
    public getBuyerConversationById = (convId?: string, options?: AxiosRequestConfig) => {
        return ConversationApiFp(this.configuration).getBuyerConversationById(convId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Conversations history sorted by unread and date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationApi
     */
    public getConversations = (options?: AxiosRequestConfig) => {
        return ConversationApiFp(this.configuration).getConversations(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Conversations history for buyer sorted by unread and date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationApi
     */
    public getConversationsForBuyer = (options?: AxiosRequestConfig) => {
        return ConversationApiFp(this.configuration).getConversationsForBuyer(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Conversations history for seller sorted by unread and date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationApi
     */
    public getConversationsForSeller = (options?: AxiosRequestConfig) => {
        return ConversationApiFp(this.configuration).getConversationsForSeller(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Sellers Messages by ConversationId
     * @param {string} convId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationApi
     */
    public getSellerConversationById = (convId?: string, options?: AxiosRequestConfig) => {
        return ConversationApiFp(this.configuration).getSellerConversationById(convId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Conversation or add Messages by ConversationId
     * @param {Conversation} conversation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationApi
     */
    public updateConversationById = (conversation?: Conversation, options?: AxiosRequestConfig) => {
        return ConversationApiFp(this.configuration).updateConversationById(conversation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Conversation or add Messages by ConversationId for buyer
     * @param {Conversation} conversation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationApi
     */
    public updateConversationByIdForBuyer = (conversation?: Conversation, options?: AxiosRequestConfig) => {
        return ConversationApiFp(this.configuration).updateConversationByIdForBuyer(conversation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Conversation or add Messages by ConversationId for seller
     * @param {Conversation} conversation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationApi
     */
    public updateConversationByIdForSeller = (conversation?: Conversation, options?: AxiosRequestConfig) => {
        return ConversationApiFp(this.configuration).updateConversationByIdForSeller(conversation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload an attachment to the message
     * @param {string} conversationId 
     * @param {undefined} [attachment] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationApi
     */
    public uploadMessageAttachment = (conversationId?: string, attachment?: undefined, options?: AxiosRequestConfig) => {
        return ConversationApiFp(this.configuration).uploadMessageAttachment(conversationId, attachment, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OfferApi - axios parameter creator
 * @export
 */
export const OfferApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get offer history for the user sorted by date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOfferHistory: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/common/offer/buyer/history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get offer history for the user sorted by date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSellerOfferHistory: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/common/offer/seller/history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OfferApi - functional programming interface
 * @export
 */
export const OfferApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OfferApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get offer history for the user sorted by date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOfferHistory(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfferResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOfferHistory(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get offer history for the user sorted by date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSellerOfferHistory(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfferResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSellerOfferHistory(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OfferApi - factory interface
 * @export
 */
export const OfferApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OfferApiFp(configuration)
    return {
        /**
         * 
         * @summary Get offer history for the user sorted by date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOfferHistory(options?: undefined): AxiosPromise<OfferResponse> {
            return localVarFp.getOfferHistory(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get offer history for the user sorted by date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSellerOfferHistory(options?: undefined): AxiosPromise<OfferResponse> {
            return localVarFp.getSellerOfferHistory(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OfferApi - object-oriented interface
 * @export
 * @class OfferApi
 * @extends {BaseAPI}
 */
export class OfferApi extends BaseAPI {
    /**
     * 
     * @summary Get offer history for the user sorted by date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public getOfferHistory = (options?: AxiosRequestConfig) => {
        return OfferApiFp(this.configuration).getOfferHistory(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get offer history for the user sorted by date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public getSellerOfferHistory = (options?: AxiosRequestConfig) => {
        return OfferApiFp(this.configuration).getSellerOfferHistory(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductApi - axios parameter creator
 * @export
 */
export const ProductApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete attachment for product. Send the name returned in POST response.
         * @param {string} cardId 
         * @param {string} productId 
         * @param {string} fileName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductAttachment: async (cardId?: string, productId?: string, fileName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/common/card/{cardId}/product/{productId}/attachment/{fileName}`
                .replace(`{${"cardId"}}`, encodeURIComponent(String(cardId)))
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)))
                .replace(`{${"fileName"}}`, encodeURIComponent(String(fileName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload an attachment to the product
         * @param {string} cardId 
         * @param {string} productId 
         * @param {undefined} [attachment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadProductAttachment: async (cardId?: string, productId?: string, attachment?: undefined, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/common/card/{cardId}/product/{productId}/attachment`
                .replace(`{${"cardId"}}`, encodeURIComponent(String(cardId)))
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (attachment !== undefined) { 
                localVarFormParams.append('attachment', attachment as undefined);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductApi - functional programming interface
 * @export
 */
export const ProductApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete attachment for product. Send the name returned in POST response.
         * @param {string} cardId 
         * @param {string} productId 
         * @param {string} fileName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProductAttachment(cardId?: string, productId?: string, fileName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Attachment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProductAttachment(cardId, productId, fileName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload an attachment to the product
         * @param {string} cardId 
         * @param {string} productId 
         * @param {undefined} [attachment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadProductAttachment(cardId?: string, productId?: string, attachment?: undefined, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Attachment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadProductAttachment(cardId, productId, attachment, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductApi - factory interface
 * @export
 */
export const ProductApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete attachment for product. Send the name returned in POST response.
         * @param {string} cardId 
         * @param {string} productId 
         * @param {string} fileName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductAttachment(cardId?: string, productId?: string, fileName?: string, options?: undefined): AxiosPromise<Attachment> {
            return localVarFp.deleteProductAttachment(cardId, productId, fileName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload an attachment to the product
         * @param {string} cardId 
         * @param {string} productId 
         * @param {undefined} [attachment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadProductAttachment(cardId?: string, productId?: string, attachment?: undefined, options?: undefined): AxiosPromise<Attachment> {
            return localVarFp.uploadProductAttachment(cardId, productId, attachment, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductApi - object-oriented interface
 * @export
 * @class ProductApi
 * @extends {BaseAPI}
 */
export class ProductApi extends BaseAPI {
    /**
     * 
     * @summary Delete attachment for product. Send the name returned in POST response.
     * @param {string} cardId 
     * @param {string} productId 
     * @param {string} fileName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public deleteProductAttachment = (cardId?: string, productId?: string, fileName?: string, options?: AxiosRequestConfig) => {
        return ProductApiFp(this.configuration).deleteProductAttachment(cardId, productId, fileName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload an attachment to the product
     * @param {string} cardId 
     * @param {string} productId 
     * @param {undefined} [attachment] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public uploadProductAttachment = (cardId?: string, productId?: string, attachment?: undefined, options?: AxiosRequestConfig) => {
        return ProductApiFp(this.configuration).uploadProductAttachment(cardId, productId, attachment, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RecentSearchControllerApi - axios parameter creator
 * @export
 */
export const RecentSearchControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {RecentSearchDto} recentSearchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRecentSearch: async (recentSearchDto?: RecentSearchDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/common/search/recent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(recentSearchDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllBySessionId: async (sessionId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/common/search/recent/all/{sessionId}`
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllByUserId: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/common/search/recent/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRecentSearchBySessionId: async (sessionId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/common/search/recent/all/{sessionId}`
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRecentSearchByUserId: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/common/search/recent/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mergeRecentSearches: async (sessionId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/common/search/recent/merge/{sessionId}`
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RecentSearchControllerApi - functional programming interface
 * @export
 */
export const RecentSearchControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RecentSearchControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {RecentSearchDto} recentSearchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addRecentSearch(recentSearchDto?: RecentSearchDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecentSearchDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addRecentSearch(recentSearchDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAllBySessionId(sessionId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAllBySessionId(sessionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAllByUserId(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAllByUserId(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllRecentSearchBySessionId(sessionId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RecentSearchDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllRecentSearchBySessionId(sessionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllRecentSearchByUserId(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RecentSearchDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllRecentSearchByUserId(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mergeRecentSearches(sessionId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mergeRecentSearches(sessionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RecentSearchControllerApi - factory interface
 * @export
 */
export const RecentSearchControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RecentSearchControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {RecentSearchDto} recentSearchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRecentSearch(recentSearchDto?: RecentSearchDto, options?: undefined): AxiosPromise<RecentSearchDto> {
            return localVarFp.addRecentSearch(recentSearchDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllBySessionId(sessionId?: string, options?: undefined): AxiosPromise<void> {
            return localVarFp.deleteAllBySessionId(sessionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllByUserId(options?: undefined): AxiosPromise<void> {
            return localVarFp.deleteAllByUserId(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRecentSearchBySessionId(sessionId?: string, options?: undefined): AxiosPromise<Array<RecentSearchDto>> {
            return localVarFp.getAllRecentSearchBySessionId(sessionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRecentSearchByUserId(options?: undefined): AxiosPromise<Array<RecentSearchDto>> {
            return localVarFp.getAllRecentSearchByUserId(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mergeRecentSearches(sessionId?: string, options?: undefined): AxiosPromise<void> {
            return localVarFp.mergeRecentSearches(sessionId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RecentSearchControllerApi - object-oriented interface
 * @export
 * @class RecentSearchControllerApi
 * @extends {BaseAPI}
 */
export class RecentSearchControllerApi extends BaseAPI {
    /**
     * 
     * @param {RecentSearchDto} recentSearchDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecentSearchControllerApi
     */
    public addRecentSearch = (recentSearchDto?: RecentSearchDto, options?: AxiosRequestConfig) => {
        return RecentSearchControllerApiFp(this.configuration).addRecentSearch(recentSearchDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} sessionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecentSearchControllerApi
     */
    public deleteAllBySessionId = (sessionId?: string, options?: AxiosRequestConfig) => {
        return RecentSearchControllerApiFp(this.configuration).deleteAllBySessionId(sessionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecentSearchControllerApi
     */
    public deleteAllByUserId = (options?: AxiosRequestConfig) => {
        return RecentSearchControllerApiFp(this.configuration).deleteAllByUserId(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} sessionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecentSearchControllerApi
     */
    public getAllRecentSearchBySessionId = (sessionId?: string, options?: AxiosRequestConfig) => {
        return RecentSearchControllerApiFp(this.configuration).getAllRecentSearchBySessionId(sessionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecentSearchControllerApi
     */
    public getAllRecentSearchByUserId = (options?: AxiosRequestConfig) => {
        return RecentSearchControllerApiFp(this.configuration).getAllRecentSearchByUserId(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} sessionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecentSearchControllerApi
     */
    public mergeRecentSearches = (sessionId?: string, options?: AxiosRequestConfig) => {
        return RecentSearchControllerApiFp(this.configuration).mergeRecentSearches(sessionId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReferenceApi - axios parameter creator
 * @export
 */
export const ReferenceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all Carrier Package references
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCarrierPackages: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/common/reference/carrier-packages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all message references
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessageReference: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/common/reference/message`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all product references
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductReference: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/common/reference/product`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all Referral Channel references
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferralChannels: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/common/reference/referral-channels`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all refund reasons 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRefundReasons: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/common/reference/refund`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all sport references
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSportReference: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/common/reference/sport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReferenceApi - functional programming interface
 * @export
 */
export const ReferenceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReferenceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get all Carrier Package references
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCarrierPackages(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CarrierPackage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCarrierPackages(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all message references
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMessageReference(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageReference>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMessageReference(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all product references
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductReference(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductReference(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all Referral Channel references
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReferralChannels(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReferralChannel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReferralChannels(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all refund reasons 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRefundReasons(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductReference>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRefundReasons(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all sport references
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSportReference(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SportReference>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSportReference(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReferenceApi - factory interface
 * @export
 */
export const ReferenceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReferenceApiFp(configuration)
    return {
        /**
         * 
         * @summary Get all Carrier Package references
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCarrierPackages(options?: undefined): AxiosPromise<CarrierPackage> {
            return localVarFp.getCarrierPackages(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all message references
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessageReference(options?: undefined): AxiosPromise<MessageReference> {
            return localVarFp.getMessageReference(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all product references
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductReference(options?: undefined): AxiosPromise<string> {
            return localVarFp.getProductReference(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all Referral Channel references
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferralChannels(options?: undefined): AxiosPromise<ReferralChannel> {
            return localVarFp.getReferralChannels(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all refund reasons 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRefundReasons(options?: undefined): AxiosPromise<ProductReference> {
            return localVarFp.getRefundReasons(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all sport references
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSportReference(options?: undefined): AxiosPromise<SportReference> {
            return localVarFp.getSportReference(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReferenceApi - object-oriented interface
 * @export
 * @class ReferenceApi
 * @extends {BaseAPI}
 */
export class ReferenceApi extends BaseAPI {
    /**
     * 
     * @summary Get all Carrier Package references
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceApi
     */
    public getCarrierPackages = (options?: AxiosRequestConfig) => {
        return ReferenceApiFp(this.configuration).getCarrierPackages(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all message references
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceApi
     */
    public getMessageReference = (options?: AxiosRequestConfig) => {
        return ReferenceApiFp(this.configuration).getMessageReference(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all product references
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceApi
     */
    public getProductReference = (options?: AxiosRequestConfig) => {
        return ReferenceApiFp(this.configuration).getProductReference(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all Referral Channel references
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceApi
     */
    public getReferralChannels = (options?: AxiosRequestConfig) => {
        return ReferenceApiFp(this.configuration).getReferralChannels(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all refund reasons 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceApi
     */
    public getRefundReasons = (options?: AxiosRequestConfig) => {
        return ReferenceApiFp(this.configuration).getRefundReasons(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all sport references
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceApi
     */
    public getSportReference = (options?: AxiosRequestConfig) => {
        return ReferenceApiFp(this.configuration).getSportReference(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SavedSearchControllerApi - axios parameter creator
 * @export
 */
export const SavedSearchControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {SavedSearchDto} savedSearchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSavedSearch: async (savedSearchDto?: SavedSearchDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/common/search/saved-search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(savedSearchDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllSavedSearches: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/common/search/saved-search/delete/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSavedSearch: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/common/search/saved-search/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSavedSearchByUserId: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/common/search/saved-search/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSavedSearchById: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/common/search/saved-search/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SavedSearchControllerApi - functional programming interface
 * @export
 */
export const SavedSearchControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SavedSearchControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {SavedSearchDto} savedSearchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addSavedSearch(savedSearchDto?: SavedSearchDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addSavedSearch(savedSearchDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAllSavedSearches(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAllSavedSearches(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSavedSearch(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSavedSearch(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllSavedSearchByUserId(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SavedSearchDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllSavedSearchByUserId(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSavedSearchById(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SavedSearchDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSavedSearchById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SavedSearchControllerApi - factory interface
 * @export
 */
export const SavedSearchControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SavedSearchControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {SavedSearchDto} savedSearchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSavedSearch(savedSearchDto?: SavedSearchDto, options?: undefined): AxiosPromise<string> {
            return localVarFp.addSavedSearch(savedSearchDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllSavedSearches(options?: undefined): AxiosPromise<void> {
            return localVarFp.deleteAllSavedSearches(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSavedSearch(id?: string, options?: undefined): AxiosPromise<void> {
            return localVarFp.deleteSavedSearch(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSavedSearchByUserId(options?: undefined): AxiosPromise<Array<SavedSearchDto>> {
            return localVarFp.getAllSavedSearchByUserId(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSavedSearchById(id?: string, options?: undefined): AxiosPromise<SavedSearchDto> {
            return localVarFp.getSavedSearchById(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SavedSearchControllerApi - object-oriented interface
 * @export
 * @class SavedSearchControllerApi
 * @extends {BaseAPI}
 */
export class SavedSearchControllerApi extends BaseAPI {
    /**
     * 
     * @param {SavedSearchDto} savedSearchDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SavedSearchControllerApi
     */
    public addSavedSearch = (savedSearchDto?: SavedSearchDto, options?: AxiosRequestConfig) => {
        return SavedSearchControllerApiFp(this.configuration).addSavedSearch(savedSearchDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SavedSearchControllerApi
     */
    public deleteAllSavedSearches = (options?: AxiosRequestConfig) => {
        return SavedSearchControllerApiFp(this.configuration).deleteAllSavedSearches(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SavedSearchControllerApi
     */
    public deleteSavedSearch = (id?: string, options?: AxiosRequestConfig) => {
        return SavedSearchControllerApiFp(this.configuration).deleteSavedSearch(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SavedSearchControllerApi
     */
    public getAllSavedSearchByUserId = (options?: AxiosRequestConfig) => {
        return SavedSearchControllerApiFp(this.configuration).getAllSavedSearchByUserId(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SavedSearchControllerApi
     */
    public getSavedSearchById = (id?: string, options?: AxiosRequestConfig) => {
        return SavedSearchControllerApiFp(this.configuration).getSavedSearchById(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SavedSellerSearchControllerApi - axios parameter creator
 * @export
 */
export const SavedSellerSearchControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {SavedSellerSearchDto} savedSellerSearchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSavedSellerSearch: async (savedSellerSearchDto?: SavedSellerSearchDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/common/search/saved-seller`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(savedSellerSearchDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSavedSellerSearch: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/common/search/saved-seller/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSavedSellerSearchByUserId: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/common/search/saved-seller/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSavedSellerSearchById: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/common/search/saved-seller/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SavedSellerSearchControllerApi - functional programming interface
 * @export
 */
export const SavedSellerSearchControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SavedSellerSearchControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {SavedSellerSearchDto} savedSellerSearchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addSavedSellerSearch(savedSellerSearchDto?: SavedSellerSearchDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addSavedSellerSearch(savedSellerSearchDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSavedSellerSearch(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSavedSellerSearch(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllSavedSellerSearchByUserId(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SavedSellerSearchDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllSavedSellerSearchByUserId(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSavedSellerSearchById(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SavedSellerSearchDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSavedSellerSearchById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SavedSellerSearchControllerApi - factory interface
 * @export
 */
export const SavedSellerSearchControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SavedSellerSearchControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {SavedSellerSearchDto} savedSellerSearchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSavedSellerSearch(savedSellerSearchDto?: SavedSellerSearchDto, options?: undefined): AxiosPromise<string> {
            return localVarFp.addSavedSellerSearch(savedSellerSearchDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSavedSellerSearch(id?: string, options?: undefined): AxiosPromise<void> {
            return localVarFp.deleteSavedSellerSearch(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSavedSellerSearchByUserId(options?: undefined): AxiosPromise<Array<SavedSellerSearchDto>> {
            return localVarFp.getAllSavedSellerSearchByUserId(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSavedSellerSearchById(id?: string, options?: undefined): AxiosPromise<SavedSellerSearchDto> {
            return localVarFp.getSavedSellerSearchById(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SavedSellerSearchControllerApi - object-oriented interface
 * @export
 * @class SavedSellerSearchControllerApi
 * @extends {BaseAPI}
 */
export class SavedSellerSearchControllerApi extends BaseAPI {
    /**
     * 
     * @param {SavedSellerSearchDto} savedSellerSearchDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SavedSellerSearchControllerApi
     */
    public addSavedSellerSearch = (savedSellerSearchDto?: SavedSellerSearchDto, options?: AxiosRequestConfig) => {
        return SavedSellerSearchControllerApiFp(this.configuration).addSavedSellerSearch(savedSellerSearchDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SavedSellerSearchControllerApi
     */
    public deleteSavedSellerSearch = (id?: string, options?: AxiosRequestConfig) => {
        return SavedSellerSearchControllerApiFp(this.configuration).deleteSavedSellerSearch(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SavedSellerSearchControllerApi
     */
    public getAllSavedSellerSearchByUserId = (options?: AxiosRequestConfig) => {
        return SavedSellerSearchControllerApiFp(this.configuration).getAllSavedSellerSearchByUserId(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SavedSellerSearchControllerApi
     */
    public getSavedSellerSearchById = (id?: string, options?: AxiosRequestConfig) => {
        return SavedSellerSearchControllerApiFp(this.configuration).getSavedSellerSearchById(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create User
         * @param {User} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: async (user?: User, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/common/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete attachment for user. Send the name returned in POST response.
         * @param {string} fileName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserAttachment: async (fileName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/common/user/profile/attachment/{fileName}`
                .replace(`{${"fileName"}}`, encodeURIComponent(String(fileName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get User details using user email id. Verify if used on frontend or just B2C.
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserByEmailId: async (email?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/common/user/{email}`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve User
         * @param {User} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveUser: async (user?: User, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/common/user/role`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload an attachment
         * @param {undefined} [attachment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadUserAttachment: async (attachment?: undefined, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/common/user/profile/attachment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (attachment !== undefined) { 
                localVarFormParams.append('attachment', attachment as undefined);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create User
         * @param {User} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUser(user?: User, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUser(user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete attachment for user. Send the name returned in POST response.
         * @param {string} fileName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserAttachment(fileName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserAttachment(fileName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get User details using user email id. Verify if used on frontend or just B2C.
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserByEmailId(email?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserByEmailId(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve User
         * @param {User} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveUser(user?: User, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveUser(user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload an attachment
         * @param {undefined} [attachment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadUserAttachment(attachment?: undefined, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Attachment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadUserAttachment(attachment, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @summary Create User
         * @param {User} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(user?: User, options?: undefined): AxiosPromise<UserResponse> {
            return localVarFp.createUser(user, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete attachment for user. Send the name returned in POST response.
         * @param {string} fileName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserAttachment(fileName?: string, options?: undefined): AxiosPromise<void> {
            return localVarFp.deleteUserAttachment(fileName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get User details using user email id. Verify if used on frontend or just B2C.
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserByEmailId(email?: string, options?: undefined): AxiosPromise<User> {
            return localVarFp.getUserByEmailId(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve User
         * @param {User} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveUser(user?: User, options?: undefined): AxiosPromise<UserResponse> {
            return localVarFp.retrieveUser(user, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload an attachment
         * @param {undefined} [attachment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadUserAttachment(attachment?: undefined, options?: undefined): AxiosPromise<Attachment> {
            return localVarFp.uploadUserAttachment(attachment, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @summary Create User
     * @param {User} user 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public createUser = (user?: User, options?: AxiosRequestConfig) => {
        return UserApiFp(this.configuration).createUser(user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete attachment for user. Send the name returned in POST response.
     * @param {string} fileName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteUserAttachment = (fileName?: string, options?: AxiosRequestConfig) => {
        return UserApiFp(this.configuration).deleteUserAttachment(fileName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get User details using user email id. Verify if used on frontend or just B2C.
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserByEmailId = (email?: string, options?: AxiosRequestConfig) => {
        return UserApiFp(this.configuration).getUserByEmailId(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve User
     * @param {User} user 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public retrieveUser = (user?: User, options?: AxiosRequestConfig) => {
        return UserApiFp(this.configuration).retrieveUser(user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload an attachment
     * @param {undefined} [attachment] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public uploadUserAttachment = (attachment?: undefined, options?: AxiosRequestConfig) => {
        return UserApiFp(this.configuration).uploadUserAttachment(attachment, options).then((request) => request(this.axios, this.basePath));
    }
}


