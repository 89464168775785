export const ACCESS_TOKEN = 'ACCESS_TOKEN';
export const IS_LOADING = 'IS_LOADING';
export const PRODUCT_IMAGE = 'PRODUCT_IMAGE';
export const PRODUCT_RESET = 'PRODUCT_RESET';
export const SELLER_SELECTED = 'SELLER_SELECTED';
export const SELLER_RESET = 'SELLER_RESET';
export const SELLER_INVENTORY_URL = 'SELLER_INVENTORY_URL';
export const CARD_IMAGE_DEFAULT = 'CARD_IMAGE_DEFAULT';
export const CARD_IMAGE_SELECTED = 'CARD_IMAGE_SELECTED';
export const PRODUCT_SELECTED = 'PRODUCT_SELECTED';
export const NAVIGATE_MOBILE_SELL = 'NAVIGATE_MOBILE_SELL';
export const NAVIGATE_MOBILE_LIST = 'NAVIGATE_MOBILE_LIST';
export const NAVIGATE_MOBILE_HISTORY = 'NAVIGATE_MOBILE_HISTORY';
export const CURRENT_CARD_PRODUCT_ID = 'CURRENT_CARD_PRODUCT_ID';
export const SELLERS_LOCKER_CARDS_ACTIVE_TAB = 'SELLERS_LOCKER_CARDS_ACTIVE_TAB';
export const IS_EDITING_CURRENT_LISTING_CARD = 'IS_EDITING_CURRENT_LISTING_CARD';
export const CURRENT_LISTING_CARD = 'CURRENT_LISTING_CARD';
export const SELLER_STOREFRONT_SELECTED = 'SELLER_STOREFRONT_SELECTED';
export const SELLER_STOREFRONT_RESET = 'SELLER_STOREFRONT_RESET';
export const SESSION_IN_STOCK = 'SESSION_IN_STOCK';
export const SET_MARKETPLACE_SCROLLABLE_ID = 'SET_MARKETPLACE_SCROLLABLE_ID';
export const SET_SELLER_SCROLLABLE_ID = 'SET_SELLER_SCROLLABLE_ID';
export const SET_BULK_UPLOAD_SELECTIONS = 'SET_BULK_UPLOAD_SPORT_SELECTIONS';
export const RESET_BULK_UPLOAD_SELECTIONS = 'RESET_BULK_UPLOAD_SPORT_SELECTIONS';
export const BULK_UPLOAD_FORM_DIRTY = 'BULK_UPLOAD_FORM_DIRTY';
export const BULK_UPLOAD_YEAR = 'BULK_UPLOAD_YEAR';
export const BULK_UPLOAD_SET_NAME = 'BULK_UPLOAD_SET_NAME';
export const BULK_UPLOAD_VARIANT = 'BULK_UPLOAD_VARIANT';
export const BULK_UPLOAD_VARIANT_NAME = 'BULK_UPLOAD_VARIANT_NAME';
export const BUYER_MESSAGES_COUNT = 'BUYER_MESSAGES_COUNT';
export const SELLER_MESSAGES_COUNT = 'SELLER_MESSAGES_COUNT';
export const UPDATE_SEARCH_SORT_PARAM = 'UPDATE_SEARCH_SORT_PARAM';
export const MOBILE_DEVICE_FOOTER_NAV_HOME = 'MOBILE_DEVICE_FOOTER_NAV_HOME';
export const MOBILE_DEVICE_FOOTER_NAV_ACCOUNT = 'MOBILE_DEVICE_FOOTER_NAV_ACCOUNT';
export const MOBILE_DEVICE_FOOTER_NAV_SEARCH = 'MOBILE_DEVICE_FOOTER_NAV_SEARCH';
export const MOBILE_DEVICE_FOOTER_NAV_FAQS = 'MOBILE_DEVICE_FOOTER_NAV_FAQS';
export const MOBILE_DEVICE_FOOTER_NAV_SELLING = 'MOBILE_DEVICE_FOOTER_NAV_SELLING';
export const BREADCRUMBS_CARD_CONDITION = 'BREADCRUMBS_CONDITION';
export const BREADCRUMBS_IN_STOCK = 'BREADCRUMBS_IN_STOCK';
export const BREADCRUMBS_SORT_ORDER = 'BREADCRUMBS_SORT_ORDER';
export const HAS_VERIFIED_MICRO_DEPOSITS = 'HAS_VERIFIED_MICRO_DEPOSITS';
export const SET_SELLERS_LOCKER_USER = 'SET_SELLERS_LOCKER_USER';
export const PRICE_RANGE_RESET = 'PRICE_RANGE_RESET';
export const SHOW_ALL_LISTINGS = 'SHOW_ALL_LISTINGS';
export const HAS_NEW_CART_QUANTITY = 'HAS_NEW_CART_QUANTITY';
export const PWA_SETTINGS = 'PWA_SETTINGS';
export const PWA_NAVBAR_ACTIVE_TAB = 'PWA_NAVBAR_ACTIVE_TAB';
export const PWA_BUYER_DASHBOARD_SELECTION = 'PWA_BUYER_DASHBOARD_SELECTION';
export const PWA_SELLER_STOREFRONT_FEEDBACK_RATING = 'PWA_SELLER_STOREFRONT_FEEDBACK_RATING';
export const PWA_SELLER_STOREFRONT_CARDS_SOLD = 'PWA_SELLER_STOREFRONT_CARDS_SOLD';
export const PWA_PLAYER_NAME = 'PWA_PLAYER_NAME';
export const PWA_CARD_NUMBER = 'PWA_CARD_NUMBER';
