import React, {memo, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import NavigationClient from '../../../client/s3/mpNavigation/navigationClient';
import {useQuery} from '../../../packages/core/src/hooks/useQuery';
import useScreenSnap from '../../../packages/core/src/hooks/useScreenSnap';
import {RootState} from '../../../redux/reducers';
import DesktopNav from './desktopNav/desktopNav';
import MobileNav from './mobileNav/mobileNav';
import PWANav from './pwaNav/pwaNav';

function MarketplaceHeader() {
    const {isTablet, isMobile} = useScreenSnap();
    const {loading: menusLoading, data: menus} = useQuery(() => NavigationClient.getMenus(), []);
    const {loading: userMenuLoading, data: userMenu} = useQuery(() => NavigationClient.getUserMenus(), []);
    const marketplaceState = useSelector((state: RootState) => state.marketplace);

    const [pwaOS, setPwaOS] = useState(null);
    useEffect(() => {
        if (marketplaceState) {
            if (marketplaceState.pwaSettings.pwaOS !== '') {
                setPwaOS(marketplaceState.pwaSettings.pwaOS);
            }
        }
    }, [marketplaceState]);

    return !isTablet && !isMobile ? (
        <DesktopNav menus={menus} menusLoading={menusLoading} userMenuLoading={userMenuLoading} userMenu={userMenu} />
    ) : (
        <>
            {pwaOS !== null && <PWANav menus={menus} menusLoading={menusLoading} userMenuLoading={userMenuLoading} userMenu={userMenu} />}
            {pwaOS === null && (
                <MobileNav menus={menus} menusLoading={menusLoading} userMenuLoading={userMenuLoading} userMenu={userMenu} />
            )}
        </>
    );
}

export default memo(MarketplaceHeader);
