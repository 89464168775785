/* eslint-disable @typescript-eslint/no-unused-vars */
import {Grid} from '@material-ui/core';
import {makeStyles, Theme} from '@material-ui/core/styles';
// import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import {useAuthFunctions} from '../../../../AuthContext';
import BSCTypography from '../../../../features/common/bscTypography/bscTypography';
import {RootState} from '../../../../redux/reducers';
import * as constants from '../../../../shared/constants';
import './styles.scss';

const useStyles = makeStyles((theme: Theme) => ({
    selectionsWrapper: {
        padding: '8px 8px 8px 6px !important',
    },
    selectionWrapper: {
        backgroundColor: theme.palette.common.white,
        height: 64,
        marginBottom: 6,
    },
    emptySelectionWrapper: {
        backgroundColor: 'transparent',
        padding: 8,
        marginBottom: 6,
    },
    largeIcon: {
        color: theme.palette.common.white,
        backgroundColor: 'black',
        overflow: 'hidden',
        textAlign: 'center',
        transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        borderRadius: '50%',
        width: 47,
        height: 35,
        paddingTop: 11,
    },
    largeIconHeart: {
        color: theme.palette.common.white,
        backgroundColor: 'black',
        overflow: 'hidden',
        textAlign: 'center',
        transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        borderRadius: '50%',
        width: 47,
        height: 34,
        paddingTop: 13,
    },
    centerCTA: {
        textAlign: 'center',
    },
}));

function PWABuyerDashboard() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const authFunc = useAuthFunctions();
    const marketplaceState = useSelector((state: RootState) => state.marketplace);
    const [buyerMessagesCount, setBuyerMessagesCount] = useState('');

    const handleLogout = () => {
        authFunc.logout();
    };

    const handleDashSelection = (index: number) => {
        switch (index) {
            case 0:
                history.push('/dashboard/profile');
                dispatch({
                    type: constants.PWA_BUYER_DASHBOARD_SELECTION,
                    payload: {
                        pwaBuyerDashboardSelection: 1,
                    },
                });
                break;
            case 1:
                history.push('/pwa/dashboard/search');
                dispatch({
                    type: constants.PWA_BUYER_DASHBOARD_SELECTION,
                    payload: {
                        pwaBuyerDashboardSelection: 2,
                    },
                });
                break;
            case 2:
                history.push('/dashboard/orderHistory');
                dispatch({
                    type: constants.PWA_BUYER_DASHBOARD_SELECTION,
                    payload: {
                        pwaBuyerDashboardSelection: 3,
                    },
                });
                break;
            case 3:
                history.push('/dashboard/offers');
                dispatch({
                    type: constants.PWA_BUYER_DASHBOARD_SELECTION,
                    payload: {
                        pwaBuyerDashboardSelection: 4,
                    },
                });
                break;
            case 4:
                history.push('/dashboard/messages');
                dispatch({
                    type: constants.PWA_BUYER_DASHBOARD_SELECTION,
                    payload: {
                        pwaBuyerDashboardSelection: 5,
                    },
                });
                break;
            case 5:
                history.push('/dashboard/faq');
                dispatch({
                    type: constants.PWA_BUYER_DASHBOARD_SELECTION,
                    payload: {
                        pwaBuyerDashboardSelection: 6,
                    },
                });
                break;
            case 6:
                history.push('/support');
                dispatch({
                    type: constants.PWA_BUYER_DASHBOARD_SELECTION,
                    payload: {
                        pwaBuyerDashboardSelection: 7,
                    },
                });
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        if (marketplaceState) {
            if (marketplaceState.buyerMessagesCount !== '') {
                setBuyerMessagesCount(marketplaceState.buyerMessagesCount);
            } else {
                setBuyerMessagesCount('');
            }
        }
    }, [marketplaceState]);

    useEffect(() => {
        dispatch({
            type: constants.PWA_NAVBAR_ACTIVE_TAB,
            payload: {
                pwaNavbarActive: 1,
            },
        });
    }, []);

    return (
        <div className={classes.selectionsWrapper}>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container direction="row">
                        <Grid item xs={12} onClick={() => handleDashSelection(0)}>
                            <Grid container alignItems="center" className={classes.selectionWrapper}>
                                <Grid item container xs={2} justifyContent="center">
                                    <div className={classes.largeIcon}>
                                        <span className="material-symbols-outlined">person</span>
                                    </div>
                                </Grid>
                                <Grid item xs={10}>
                                    <BSCTypography size18 style={{fontWeight: 600}}>
                                        Profile
                                    </BSCTypography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} onClick={() => handleDashSelection(1)}>
                            <Grid container alignItems="center" className={classes.selectionWrapper}>
                                <Grid item container xs={2} justifyContent="center">
                                    <div className={classes.largeIconHeart}>
                                        <span className="material-symbols-outlined">favorite</span>
                                    </div>
                                </Grid>
                                <Grid item xs={10}>
                                    <BSCTypography size18 style={{fontWeight: 600}}>
                                        Saved
                                    </BSCTypography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} onClick={() => handleDashSelection(2)}>
                            <Grid container alignItems="center" className={classes.selectionWrapper}>
                                <Grid item container xs={2} justifyContent="center">
                                    <div className={classes.largeIcon}>
                                        <span className="material-symbols-outlined">list_alt</span>
                                    </div>
                                </Grid>
                                <Grid item xs={10}>
                                    <BSCTypography size18 style={{fontWeight: 600}}>
                                        Order History
                                    </BSCTypography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} onClick={() => handleDashSelection(3)}>
                            <Grid container alignItems="center" className={classes.selectionWrapper}>
                                <Grid item container xs={2} justifyContent="center">
                                    <div className={classes.largeIcon}>
                                        <span className="material-symbols-outlined">currency_exchange</span>
                                    </div>
                                </Grid>
                                <Grid item xs={10}>
                                    <BSCTypography size18 style={{fontWeight: 600}}>
                                        Offers
                                    </BSCTypography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} onClick={() => handleDashSelection(4)}>
                            <Grid container alignItems="center" className={classes.selectionWrapper}>
                                <Grid item container xs={2} justifyContent="center">
                                    <div className={classes.largeIcon}>
                                        <span className="material-symbols-outlined">mail</span>
                                    </div>
                                </Grid>
                                <Grid item xs={10}>
                                    {buyerMessagesCount === '' && (
                                        <BSCTypography size18 style={{fontWeight: 600}}>
                                            Messages
                                        </BSCTypography>
                                    )}
                                    {buyerMessagesCount !== '' && (
                                        <BSCTypography size18 style={{fontWeight: 600}}>
                                            {`Messages (${buyerMessagesCount})`}
                                        </BSCTypography>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} onClick={() => handleDashSelection(5)}>
                            <Grid container alignItems="center" className={classes.selectionWrapper}>
                                <Grid item container xs={2} justifyContent="center">
                                    <div className={classes.largeIcon}>
                                        <span className="material-symbols-outlined">question_mark</span>
                                    </div>
                                </Grid>
                                <Grid item xs={10}>
                                    <BSCTypography size18 style={{fontWeight: 600}}>
                                        FAQs
                                    </BSCTypography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} onClick={() => handleDashSelection(6)}>
                            <Grid container alignItems="center" className={classes.selectionWrapper}>
                                <Grid item container xs={2} justifyContent="center">
                                    <div className={classes.largeIcon}>
                                        <span className="material-symbols-outlined">contact_support</span>
                                    </div>
                                </Grid>
                                <Grid item xs={10}>
                                    <BSCTypography size18 style={{fontWeight: 600}}>
                                        Contact Support
                                    </BSCTypography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container alignItems="center" className={classes.selectionWrapper} onClick={handleLogout}>
                                <Grid item container xs={2} justifyContent="center">
                                    <div className={classes.largeIcon}>
                                        <span className="material-symbols-outlined">logout</span>{' '}
                                    </div>
                                </Grid>
                                <Grid item xs={10}>
                                    <BSCTypography size18 style={{fontWeight: 600}}>
                                        Sign Out
                                    </BSCTypography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

export default PWABuyerDashboard;
