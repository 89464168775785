import Grid from '@material-ui/core/Grid';
import {makeStyles, Theme} from '@material-ui/core/styles';
import React from 'react';
import BSCButton from '../../../../features/common/bscButton/bscButton';
import BSCDivider from '../../../../features/common/bscDivider/bscDivider';
import BSCModal from '../../../../features/common/bscModal/bscModal';
import BSCTypography from '../../../../features/common/bscTypography/bscTypography';

const useStyles = makeStyles((theme: Theme) => ({
    marginRight: {
        marginRight: 16,
    },
    marginBottom: {
        marginBottom: 16,
    },
    overflowHidden: {
        overflow: 'hidden',
    },
    modalDimensions: {
        height: '96px',
        [theme.breakpoints.down('xs')]: {height: '155px'},
    },
    signInConfirmation: {
        fontSize: '20px !important',
        fontFamily: '"Poppins", sans-serif',
        fontWeight: 400,
        lineHeight: '1.167.toFixed',
        letterSpacing: 0.5,
    },
}));

interface SignInAdvisoryModalProps {
    open: boolean;
    onClose: boolean;
    isSellerStorefrontPresent: boolean;
}

function SignInAdvisoryModal({open, onClose, isSellerStorefrontPresent}: SignInAdvisoryModalProps) {
    const classes = useStyles();
    return (
        <BSCModal open={open} onClose={onClose}>
            <div className={classes.modalDimensions}>
                <BSCTypography
                    align="center"
                    label={`To Save ${isSellerStorefrontPresent ? 'Seller search' : 'Search criteria'} you need to Sign In to your Account`}
                    className={classes.signInConfirmation}
                />
                <BSCDivider />
                <div className={classes.overflowHidden}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} style={{textAlign: 'center'}}>
                            <BSCButton fullWidth={false} color="secondary" onClick={() => onClose(true)}>
                                <BSCTypography label="Ok" />
                            </BSCButton>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </BSCModal>
    );
}

export default SignInAdvisoryModal;
